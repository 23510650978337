import { Form, Formik } from "formik";
import _ from "lodash";
import { ReactNode } from "react";
import { Alert, Button, Col, Form as BsForm, Row } from "react-bootstrap";
import * as Yup from "yup";

import { User } from "../../../api";
import { FormItem } from "../../../common/components/form-item";

const userIdSchema = Yup.object({
  userId: Yup.string()
    .email()
    .lowercase("User ID needs to be lowercase")
    .required("User ID is required")
    .strict(),
});

export type Output = Pick<User, "userId" | "projects" | "groups">;

interface UserIdFormProps {
  onSubmit: (values: string) => void;
  isSubmitting?: boolean;
  userIds: string[];
  userId?: string;
  children: ({
    fields,
    submit,
  }: {
    fields: ReactNode;
    submit: ReactNode;
  }) => ReactNode;
  submitButtonText?: string;
}

export const UserIdForm = ({
  onSubmit,
  userIds,
  userId = "",
  isSubmitting = false,
  submitButtonText = "Ok",
  children,
}: UserIdFormProps) => {
  const isExistingUser = _.includes(userIds, userId);
  return (
    <Formik
      validationSchema={userIdSchema}
      onSubmit={({ userId }) => onSubmit(userId)}
      initialValues={{ userId }}
    >
      {({ values, submitForm }) => (
        <Form method="post" noValidate>
          <fieldset disabled={isSubmitting}>
            {children({
              fields: (
                <Row className="align-items-end">
                  <Col lg="6">
                    <FormItem
                      className="mb-3"
                      name="userId"
                      label="ID"
                      type="text"
                      placeholder="email"
                      readOnly={isExistingUser}
                      as={BsForm.Control}
                      list="userIdOptions"
                    />
                    <datalist id="userIdOptions">
                      {_.map(
                        _.take(
                          _.sortBy(
                            _.filter(_.map(userIds, _.toLower), (userId) =>
                              _.includes(userId, values.userId)
                            )
                          ),
                          10
                        ),
                        (userId) => (
                          <option key={userId} value={userId} />
                        )
                      )}
                    </datalist>
                  </Col>
                  {!_.isError(
                    _.attempt(() => userIdSchema.validateSync(values))
                  ) && (
                    <Col lg="6">
                      <Alert variant="primary">
                        {_.includes(userIds, values.userId)
                          ? "Found matching user."
                          : "User does not yet exist."}
                      </Alert>
                    </Col>
                  )}
                </Row>
              ),
              submit: (
                <Button variant="primary" onClick={submitForm}>
                  {submitButtonText}
                </Button>
              ),
            })}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
