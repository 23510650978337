import { ReactNode } from "react";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { Admin } from "./admin.component";

interface ConnectedAdmin {
  children?: ReactNode;
}

export const ConnectedAdmin = ({ children }: ConnectedAdmin) => {
  const auth = useAuth();
  if (!isAuthenticatedValue(auth)) {
    throw new Error("Not authenticated.");
  }
  return <Admin hasRole={auth.hasRole}>{children}</Admin>;
};
