import "./report-page.scss";

import clsx from "clsx";
import _ from "lodash";
import { DateTime } from "luxon";
import { ReactNode, useContext } from "react";

import { HeaderTitle } from "../../../common/components/header-title";
import { Logo } from "../../../common/components/logo";
import { TIMEZONE_API } from "../../constants";
import { ReportContext } from "../../screens/report/report.component";
import { ReportFooter } from "../report-footer";

interface ReportPageProps {
  children: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  intro?: ReactNode;
}

export const ReportPage = ({
  children,
  title,
  subtitle,
  intro,
}: ReportPageProps) => {
  const { timestamp, preview } = useContext(ReportContext);
  return (
    <div
      className={clsx("easi-report-page d-flex flex-column", {
        "easi-report-page--previewing": preview,
      })}
    >
      <div className="d-flex align-items-center">
        <Logo />
        <HeaderTitle />
      </div>
      <hr className="mt-0 mx-3 mb-4 opacity-25" />
      <div className="px-5 flex-grow-1">
        {!_.isUndefined(title) && (
          <h2
            className={clsx("fw-bold text-primary", {
              "mb-4": _.isUndefined(intro),
            })}
          >
            {title}
          </h2>
        )}
        {!_.isUndefined(intro) && <p className="mb-5">{intro}</p>}
        {!_.isUndefined(subtitle) && (
          <h3 className="fw-bold mb-4">{subtitle}</h3>
        )}
        {children}
      </div>
      <ReportFooter
        timestamp={
          timestamp || DateTime.now().setZone(TIMEZONE_API).toFormat("F")
        }
      />
    </div>
  );
};
