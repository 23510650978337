import chroma from "chroma-js";
import _ from "lodash";

import csiro from "../../../csiro.module.scss";

export const fixed = [
  csiro["midday-blue"],
  csiro["fushsia"],
  csiro["gold"],
  csiro["orange"],
  csiro["lavender"],
  csiro["light-mint"],
  csiro["plum"],
  csiro["forest"],
  csiro["light-teal"],
  csiro["midnight-blue"],
];

export const generateScaleColors = (count: number) =>
  _.initial(
    chroma
      .scale([csiro["midday-blue"] || "black", "white"]) // The || "black" is so tests don't need to worry about import scss.
      .mode("lch")
      .gamma(0.4)
      .colors(count + 1)
  );
