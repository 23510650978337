import { defer, LoaderFunctionArgs } from "react-router-dom";

import { Workspaces } from "../../../api";
import { ApiMode } from "../../types";

export const workspacesLoader =
  (mode: ApiMode) =>
  async ({ request: { signal } }: LoaderFunctionArgs) =>
    defer({
      workspaces: Workspaces.fetchAndParse({
        mode,
        signal,
      }),
    });
