import "./header-title.scss";

export const HeaderTitle = () => {
  return (
    <div className="easi-header-title text-dark">
      Earth Analytics Science &amp;
      <br /> Innovation Platform
    </div>
  );
};
