import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import _ from "lodash";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { CONFIG } from "../../../config";
import paths from "../../../paths";
import { ReportPeriodId } from "../../report-periods";
import { FileDownloader, StatusError } from "../file-downloader";

interface ReportDownloaderProps {
  periodId: ReportPeriodId;
  start: string;
}

export const ReportDownloader = ({
  periodId,
  start,
}: ReportDownloaderProps) => {
  const auth = useAuth();
  if (!_.isString(CONFIG.PDF_REPORT_URL) || !isAuthenticatedValue(auth)) {
    return null;
  }
  return (
    <FileDownloader
      requestConfig={{
        method: "get",
        url: `${_.trimEnd(
          CONFIG.PDF_REPORT_URL,
          "/"
        )}/${paths.ACTIVITY_REPORT_PDF}?start=${start}&period-id=${periodId}`,
        headers: {
          "pdf-config": JSON.stringify(auth.tokens),
        },
      }}
      name="EASI costs report.pdf"
    >
      {({ onClick, isLoading, error }) => {
        if (error instanceof StatusError && error.status === 403) {
          window.location.reload();
        } else if (error) {
          console.error(error);
        }
        return (
          <button
            className={clsx("btn", {
              "btn-primary": _.isUndefined(error),
              "btn-danger": !_.isUndefined(error),
            })}
            onClick={onClick}
            disabled={isLoading}
          >
            {_.isUndefined(error) ? (
              <>
                Download
                <FontAwesomeIcon
                  icon={isLoading ? faSpinner : faDownload}
                  spin={isLoading}
                  className="ms-3"
                />
              </>
            ) : (
              "Failed. Try again!"
            )}
          </button>
        );
      }}
    </FileDownloader>
  );
};
