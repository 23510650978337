import "./report.scss";

import clsx from "clsx";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import csiro from "../../../csiro.module.scss";
import attributes from "../../attributes";
import { Chart } from "../../components/chart";
import { generateChartData } from "../../components/chart";
import { generateCostsChartData } from "../../components/costs";
import { ReportPage } from "../../components/report-page";
import { ReportResourceUsageChart } from "../../components/report-resource-usage-chart";
import { Stats } from "../../components/stats";
import { generateStats } from "../../components/stats";
import { Trends } from "../../components/trends";
import { generateTrendsData } from "../../components/trends";
import {
  AttributeId,
  IntervalId,
  ResourceId,
  TIMEZONE_API,
  TIMEZONE_DISPLAY,
} from "../../constants";
import intervals from "../../intervals";
import {
  ActivityData,
  AggregatedActivityDataByInterval,
  AttributeOptions,
  AttributeValue,
  DatetimeRange,
  GroupedCostData,
} from "../../types";
import {
  calculateTotalEfficiency,
  extractUsageData,
  getGroupsFromData,
} from "../../utils/data";
import { formatCurrency } from "../../utils/format-currency";

interface ReportContextValue {
  preview: boolean;
  timestamp?: string;
}

export const ReportContext = React.createContext<ReportContextValue>({
  preview: false,
  timestamp: DateTime.now().setZone(TIMEZONE_API).toFormat("F"),
});

export interface ReportProps {
  inner: {
    activityData: ActivityData[];
    costsData: GroupedCostData[];
    datetimeRange: DatetimeRange;
    intervalId: IntervalId;
  };
  outer: {
    activityData: ActivityData[];
    costsData: GroupedCostData[];
    datetimeRange: DatetimeRange;
    intervalId: IntervalId;
  };
  attributeOptions: AttributeOptions;
  user: {
    name: string;
  };
  title: string;
}

export const Report = ({
  inner,
  outer,
  attributeOptions,
  user,
  title,
}: ReportProps) => {
  const { preview } = useContext(ReportContext);
  const grouping = undefined;
  const groups: AttributeValue[] = [];
  const outerCostsChartData = generateCostsChartData({
    data: outer.costsData,
  });
  const outerEfficiencyChartData = generateChartData({
    data: outer.activityData,
    groups,
    grouping: undefined,
    aggregator: (items: (ActivityData | AggregatedActivityDataByInterval)[]) =>
      calculateTotalEfficiency(items) || 0,
  });
  const resourceUtilisationData = extractUsageData(inner.activityData);
  return (
    <div className={clsx("bg-dark", { "py-3": preview })}>
      <div className="bg-white m-auto easi-costs-report__pages">
        <ReportPage>
          <h2 className="mb-5 text-primary">{title}</h2>
          <Row className="mb-5">
            <Col xs="6">
              Account <strong>{user.name}</strong> <br />
              Period start{" "}
              <strong>
                {DateTime.fromISO(inner.datetimeRange[0], {
                  zone: TIMEZONE_DISPLAY,
                }).toFormat("DDD")}
              </strong>
              <br />
              Period end{" "}
              <strong>
                {DateTime.fromISO(inner.datetimeRange[1], {
                  zone: TIMEZONE_DISPLAY,
                }).toFormat("DDD")}
              </strong>
            </Col>
            <Col xs="6" className="text-end">
              Workspaces: <strong>{attributeOptions.workspace.length}</strong>
              <br />
              Users: <strong>{attributeOptions.user.length}</strong>
            </Col>
          </Row>
          <div className="mb-5">
            <h3 className="text-start fs-4 mb-2 fw-bold">Costs overview</h3>
            <Stats
              data={generateStats({
                data: inner.costsData,
                datetimeRange: inner.datetimeRange,
                intervalId: inner.intervalId,
                groups,
                grouping,
              })}
            />
          </div>
          <h3 className="text-start fs-4 mb-4 fw-bold">{`${_.startCase(
            intervals[inner.intervalId].diurnal
          )} costs overview`}</h3>
          <div style={{ height: "300px", width: "100%" }}>
            <Chart
              data={generateCostsChartData({
                data: inner.costsData,
              })}
              grouping={grouping}
              datetimeFormat="d"
              yFormatter={formatCurrency}
              className="mb-4"
              enableTooltips={false}
              options={{
                maintainAspectRatio: false,
                animation: false,
              }}
            />
          </div>
        </ReportPage>
        {/*
        @TODO: Determine what contents of this page should be.
        <ReportPage>
          <h2 className="fw-bold text-primary mb-4">Overview</h2>
          <h3 className="fw-bold mb-4">{`${_.startCase(intervals[inner.intervalId].diurnal)} cost breakdown`}</h3>
          <p className="display-4 bg-dark bg-opacity-10 p-5">
            Breakdown of <strong className="fw-bold">costs</strong> overview
          </p>
        </ReportPage>
        */}
        {[attributes.resource, attributes.user, attributes.workspace].map(
          (attribute) => (
            <React.Fragment key={attribute.id}>
              <ReportPage
                title={_.startCase(attribute.singular)}
                subtitle={`${_.startCase(
                  intervals[inner.intervalId].diurnal
                )} usage cost`}
                intro="Text goes here"
              >
                <Chart
                  data={generateChartData({
                    data: inner.activityData,
                    groups: _.map(attributeOptions[attribute.id], "value"),
                    grouping: attribute.id,
                    aggregator: (items) => _.sum(_.map(items, "cost")),
                  })}
                  grouping={attribute.id}
                  datetimeFormat="d"
                  yFormatter={formatCurrency}
                  enableTooltips={false}
                  options={{
                    animation: false,
                  }}
                />
              </ReportPage>
              <ReportPage
                title={_.startCase(attribute.singular)}
                subtitle={`${_.startCase(
                  intervals[inner.intervalId].diurnal
                )} usage cost breakdown`}
                intro="Text goes here"
              >
                <ul className="list-group list-group-flush w-50">
                  {_.map(attributeOptions[attribute.id], (option) => (
                    <li key={option.value.id} className="list-group-item fs-7">
                      <Row>
                        <Col>{option.value.label}</Col>
                        <Col className="text-end">
                          <strong>
                            {formatCurrency(
                              _.sum(
                                _.map(
                                  _.filter(inner.activityData, {
                                    [attribute.id]: option.value,
                                  }),
                                  "cost"
                                )
                              )
                            )}
                          </strong>
                        </Col>
                      </Row>
                    </li>
                  ))}
                </ul>
              </ReportPage>
              <ReportPage
                title={_.startCase(attribute.singular)}
                subtitle={`${_.startCase(
                  intervals[inner.intervalId].diurnal
                )} usage breakdown`}
                intro="Text goes here"
              >
                <ReportResourceUsageChart
                  title="CPU Usage"
                  description="Cumulative CPU core-hours allocated."
                  activity={resourceUtilisationData}
                  isLoading={false}
                  dataControlsValue={{
                    intervalId: inner.intervalId,
                    grouping: attribute.id,
                  }}
                  yFormatter={(value) => `${_.round(value, 2)}`}
                  yLabel="Core hours"
                  resourceId={ResourceId.Cpu}
                />
                <ReportResourceUsageChart
                  title="RAM Usage"
                  description="Cumulative RAM GB-hours allocated."
                  activity={resourceUtilisationData}
                  isLoading={false}
                  dataControlsValue={{
                    intervalId: inner.intervalId,
                    grouping: attribute.id,
                  }}
                  yFormatter={(value) => `${_.round(value / 10 ** 9, 3)}`}
                  yLabel="GB hours"
                  resourceId={ResourceId.Ram}
                />
              </ReportPage>
            </React.Fragment>
          )
        )}
        <ReportPage
          title="Efficiency"
          subtitle="Resource utilisation"
          intro="Text goes here"
        >
          <Chart
            isLoading={false}
            enableTooltips={false}
            data={generateChartData({
              data: resourceUtilisationData,
              groups: getGroupsFromData(
                AttributeId.Resource,
                resourceUtilisationData
              ),
              grouping: AttributeId.Resource,
              aggregator: (items) => {
                const legit = _.filter(items, (item) =>
                  _.isNumber(item.efficiency)
                );
                return (
                  _.sum(
                    _.map(
                      legit,
                      ({ efficiency, cost }) => (efficiency as number) * cost
                    )
                  ) / _.sum(_.map(legit, "cost"))
                );
              },
            })}
            datetimeFormat={intervals[inner.intervalId].format}
            grouping={AttributeId.Resource}
            yFormatter={(value) => `${_.round(value * 100, 2)}%`}
            options={{
              scales: {
                x: {
                  stacked: false,
                },
                y: {
                  stacked: false,
                  ticks: {
                    /* eslint-disable-next-line */
                    callback: (value: any) => `${_.round(value * 100, 2)}`,
                  },
                  title: {
                    display: true,
                    text: "% efficiency",
                  },
                },
              },
            }}
          />
        </ReportPage>
        <ReportPage
          title="Trends"
          intro={`Comparision with previous ${
            intervals[outer.intervalId].plural
          }`}
        >
          <h3 className="fw-bold mb-3">Cost</h3>
          <div style={{ height: "250px", width: "100%" }}>
            <Chart
              data={_.defaultsDeep(
                _.set(
                  {},
                  "datasets[0].backgroundColor",
                  _.concat(
                    _.times(
                      _.get(
                        outerCostsChartData.datasets,
                        "[0].data.length",
                        1
                      ) - 1,
                      _.constant(csiro["steel"])
                    ),
                    csiro["midday-blue"]
                  )
                ),
                outerCostsChartData
              )}
              grouping={grouping}
              datetimeFormat={intervals[outer.intervalId].format}
              yFormatter={formatCurrency}
              enableTooltips={false}
              options={{
                maintainAspectRatio: false,
                animation: false,
              }}
            />
          </div>
          <hr className="opacity-50 border-medium" />
          <h3 className="fw-bold mb-3">Efficiency</h3>
          <div style={{ height: "250px", width: "100%" }}>
            <Chart
              data={_.defaultsDeep(
                _.set(
                  {},
                  "datasets[0].backgroundColor",
                  _.concat(
                    _.times(
                      outerEfficiencyChartData.datasets[0].data.length - 1,
                      _.constant(csiro["steel"])
                    ),
                    csiro["midday-blue"]
                  )
                ),
                outerEfficiencyChartData
              )}
              grouping={grouping}
              datetimeFormat={intervals[outer.intervalId].format}
              yFormatter={(value) => `${_.round(value * 100, 2)}%`}
              enableTooltips={false}
              options={{
                maintainAspectRatio: false,
                animation: false,
              }}
            />
          </div>
          <div className="pt-3">
            <Trends
              trends={generateTrendsData(
                _.map(
                  [
                    {
                      name: "cost",
                      data: _.get(outerCostsChartData, "datasets[0].data"),
                    },
                    {
                      name: "efficiency",
                      data: _.get(outerEfficiencyChartData, "datasets[0].data"),
                    },
                  ],
                  ({ name, data }) => ({
                    name,
                    period: intervals[outer.intervalId].id,
                    oldValue: data ? _.nth(data, -2) || 0 : 0,
                    newValue: data ? _.nth(data, -1) || 0 : 0,
                  })
                )
              )}
            />
          </div>
        </ReportPage>
      </div>
    </div>
  );
};
