import clsx from "clsx";
import { Field, FieldHookConfig, useField } from "formik";
import _ from "lodash";
import { Form } from "react-bootstrap";

interface FormItemProps {
  label: string;
  className?: string;
  readOnly?: boolean;
}

/**
 * Component that renders label, input and validation for a formik form item.
 */
export const FormItem = ({
  label,
  className,
  readOnly = false,
  ...props
}: FormItemProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <Form.Group className={className} controlId={_.uniqueId(props.name)}>
      <Form.Label>{label}</Form.Label>
      <Field
        {...field}
        {...props}
        readOnly={readOnly}
        isInvalid={!readOnly && !!meta.touched && !!meta.error}
        isValid={!readOnly && !!meta.touched && !meta.error}
      />
      <Form.Control.Feedback
        type="invalid"
        className={clsx({ "d-block": !!meta.touched && !!meta.error })}
      >
        {_.isString(meta.error) && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
