import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { Spinner } from "../../../common/components/spinner";
import { useUsers } from "./use-users";
import { Users } from "./users.component";
import { UsersLoaderData } from "./users-loader";

export interface UsersLoader {
  users: Promise<UsersLoaderData[]>;
}

export const ConnectedUsers = () => {
  const data = useLoaderData() as UsersLoader;
  const { isSubmitting, onSubmit } = useUsers();
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.users}>
        {({ users, workspaceOptions }: UsersLoaderData) => (
          <Users
            users={users}
            workspaceOptions={workspaceOptions}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Await>
    </React.Suspense>
  );
};
