import _ from "lodash";

import { fetcher } from "../auth";
import { apiUrl, transformKeysDeep } from "./utils";

export interface SpawnerGroup {
  spawnerGroupId: string;
  profileOptions: {
    name: string;
    description: string;
    image: string;
    daskImage: string;
    tags: string[];
    settings: { key: string; value: unknown }[];
    imageTagRegex: string;
    versions: { label: string; tag: string }[];
  }[];
  resourceOptions: {
    resourceOptionId: string;
    presetLabel: string;
  }[];
}

export interface SpawnerGroupsResponseItem {
  spawner_group_id: string;
  profile_options: {
    name: string;
    description: string;
    image: string;
    dask_image: string;
    tags: string[];
    settings: { key: string; value: unknown }[];
    image_tag_regex: string;
    versions: { label: string; tag: string }[];
  }[];
  resource_options: {
    resource_option_id: string;
    preset_label: string;
  }[];
}

interface RequestArguments {
  signal: AbortSignal;
}

export const SpawnerGroups = {
  parseFromApi(data: SpawnerGroupsResponseItem[]): SpawnerGroup[] {
    return transformKeysDeep(_.camelCase, data) as SpawnerGroup[];
  },
  prepareForApi(spawnerGroup: SpawnerGroup): SpawnerGroupsResponseItem {
    return transformKeysDeep(
      _.snakeCase,
      spawnerGroup
    ) as SpawnerGroupsResponseItem;
  },
  async fetch({
    signal,
  }: RequestArguments): Promise<SpawnerGroupsResponseItem[]> {
    const { data } = await fetcher({
      url: apiUrl("spawner-groups/all"),
      signal,
    });
    return data;
  },
  async fetchAndParse({ signal }: RequestArguments): Promise<SpawnerGroup[]> {
    const data = await SpawnerGroups.fetch({ signal });
    return SpawnerGroups.parseFromApi(data);
  },
  create(values: SpawnerGroup) {
    return fetcher({
      url: apiUrl(
        `spawner-groups/${encodeURIComponent(values.spawnerGroupId)}`
      ),
      method: "POST",
      data: _.omit(SpawnerGroups.prepareForApi(values), "spawner_group_id"),
    });
  },
  delete(values: SpawnerGroup) {
    return fetcher({
      url: apiUrl(
        `spawner-groups/${encodeURIComponent(values.spawnerGroupId)}`
      ),
      method: "DELETE",
    });
  },
  update(values: SpawnerGroup) {
    return fetcher({
      url: apiUrl(
        `spawner-groups/${encodeURIComponent(values.spawnerGroupId)}`
      ),
      method: "PATCH",
      data: _.omit(SpawnerGroups.prepareForApi(values), "spawner_group_id"),
    });
  },
};
