import { useNavigation, useSubmit } from "react-router-dom";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { useActionToasts } from "../../../common/hooks";

export const useWorkspace = () => {
  const auth = useAuth();
  if (!isAuthenticatedValue(auth)) {
    throw new Error("Not authed");
  }
  useActionToasts();
  const onSubmit = useSubmit();
  const { state } = useNavigation();
  const isSubmitting = state === "submitting";
  const authedUserId = auth.userId;
  return {
    authedUserId,
    onSubmit,
    isSubmitting,
  };
};
