import _ from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmerModalProps {
  onConfirm: () => void;
  children: ({ open }: { open: () => void }) => ReactNode;
  isSubmitting?: boolean;
  title: ReactNode;
  body: ReactNode;
}
export const ConfirmerModal = ({
  children,
  onConfirm,
  isSubmitting,
  title,
  body,
}: ConfirmerModalProps) => {
  const [show, setShow] = useState(false);
  const hide = () => setShow(false);
  useEffect(() => {
    if (!isSubmitting) {
      hide();
    }
  }, [isSubmitting]);
  return (
    <>
      {children({ open: () => setShow(true) })}
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton={!isSubmitting}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isSubmitting || !show}
            variant="primary"
            onClick={() => {
              onConfirm();
              if (_.isUndefined(isSubmitting)) {
                hide();
              }
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
