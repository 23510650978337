import { useActionData, useNavigation, useSubmit } from "react-router-dom";

import { Reports } from "./reports.component";
import { ReportsActionData } from "./reports-action";

export const ConnectedReports = () => {
  const onSubmit = useSubmit();
  const actionData = useActionData() as ReportsActionData;
  const navigation = useNavigation();
  return (
    <Reports
      isSubmitting={navigation.state === "submitting"}
      onSubmit={onSubmit}
      actionData={actionData}
    />
  );
};
