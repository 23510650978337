import _ from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AttributeId } from "../../constants";
import {
  AttributeOptions,
  DataControlsValue,
  DatetimeRange,
} from "../../types";
import { getAppropriateIntervalId } from "../../utils/get-appropriate-interval-id";

interface UseDataControlsArgs {
  datetimeRange: DatetimeRange;
  attributeOptions: AttributeOptions;
}

export const useDataControls = ({
  datetimeRange,
  attributeOptions,
}: UseDataControlsArgs) => {
  const navigate = useNavigate();

  const [state, setState] = useState<Omit<DataControlsValue, "datetimeRange">>({
    intervalId: getAppropriateIntervalId(datetimeRange),
    grouping: undefined,
    selectedAttributes: {},
  });

  const value = useMemo(() => {
    const next = { ...state, datetimeRange };
    const valid = _.pickBy(next.selectedAttributes, (value, key) =>
      _.find(attributeOptions[key as AttributeId], { value })
    );
    if (!_.isEqual(valid, next.selectedAttributes)) {
      next.selectedAttributes = valid;
    }
    return next;
  }, [datetimeRange, state, attributeOptions]);

  const onChange = (value: DataControlsValue) => {
    const next = { ...state };
    if (value.selectedAttributes !== state.selectedAttributes) {
      next.selectedAttributes = _.omitBy(
        {
          ...state.selectedAttributes,
          ...value.selectedAttributes,
        },
        _.isUndefined
      );
    }
    if (!_.isEqual(value.datetimeRange, datetimeRange)) {
      next.intervalId = getAppropriateIntervalId(value.datetimeRange);
      const [start, end] = value.datetimeRange;
      navigate(
        `?start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`,
        { replace: true }
      );
    }
    if (value.intervalId !== state.intervalId) {
      next.intervalId = value.intervalId;
    }
    if (value.grouping !== state.grouping) {
      next.grouping = value.grouping;
    }
    setState(next);
  };

  return { value, onChange };
};
