import _ from "lodash";

import { fetcher } from "../auth";
import { apiUrl, transformKeysDeep } from "./utils";

export interface ResourceOption {
  id: string;
  cpuCores: number;
  memoryMib: number;
  storageGb: number;
  storageType: "hdd" | "ssd";
  gpuCores: number;
  gpuType: string;
}

export interface ResourceOptionsResponseItem {
  id: string;
  cpu_cores: number;
  memory_mib: number;
  storage_gb: number;
  storage_type: "hdd" | "ssd";
  gpu_cores: number;
  gpu_type: string;
}

interface RequestArguments {
  signal: AbortSignal;
}

export const ResourceOptions = {
  parseFromApi(data: ResourceOptionsResponseItem[]): ResourceOption[] {
    return transformKeysDeep(_.camelCase, data) as ResourceOption[];
  },
  async fetch({
    signal,
  }: RequestArguments): Promise<ResourceOptionsResponseItem[]> {
    const { data } = await fetcher({
      url: apiUrl("spawner-groups/resource-options/all"),
      signal,
    });
    return data;
  },
  async fetchAndParse({ signal }: RequestArguments): Promise<ResourceOption[]> {
    const data = await ResourceOptions.fetch({ signal });
    return ResourceOptions.parseFromApi(data);
  },
};
