import _ from "lodash";

import { AttributeId, ResourceId } from "../../constants";
import { ActivityData } from "../../types";
import { DataControlsValue } from "../../types";
import {
  aggregateActivityDataByIntervalAndAttributes,
  getGroupsFromData,
} from "../../utils/data";
import { Chart } from "../chart";
import { generateChartData } from "../chart/generate-chart-data";

interface ReportResourceUsageChartProps {
  title: string;
  description: string;
  activity: ActivityData[];
  isLoading: boolean;
  dataControlsValue: Pick<DataControlsValue, "intervalId" | "grouping">;
  yFormatter(value: number): string;
  yLabel: string;
  resourceId: ResourceId;
}

// @TODO: DRY extract chart as it's the same as ResourceUsage component chart.
export const ReportResourceUsageChart = ({
  title,
  description,
  activity,
  isLoading,
  dataControlsValue,
  yFormatter,
  yLabel,
  resourceId,
}: ReportResourceUsageChartProps) => {
  const { grouping } = dataControlsValue;
  const resourceActivity = aggregateActivityDataByIntervalAndAttributes(
    _.filter(activity, (item) => item.resource.id === resourceId)
  );
  return (
    <>
      <h4>{title}</h4>
      <p>{description}</p>
      <div className="mb-5" style={{ height: "200px" }}>
        <Chart
          isLoading={isLoading}
          data={generateChartData({
            data: resourceActivity,
            groups:
              grouping === AttributeId.Resource
                ? []
                : getGroupsFromData(grouping, resourceActivity),
            grouping: grouping === AttributeId.Resource ? undefined : grouping,
            aggregator: (items) => _.sum(_.map(items, "hours")),
          })}
          datetimeFormat="d"
          grouping={grouping}
          yFormatter={(value) => `${yFormatter(value)} ${yLabel}`}
          enableTooltips={false}
          options={{
            maintainAspectRatio: false,
            animation: false,
            scales: {
              y: {
                ticks: {
                  callback: yFormatter as any, // eslint-disable-line
                },
                title: {
                  display: true,
                  text: yLabel,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
};
