import { faBugs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { Accordion } from "react-bootstrap";
import { isRouteErrorResponse } from "react-router-dom";

import { RequireAuth } from "../../../auth";
import { NOT_LOGGED_IN_ERROR } from "../../../auth/constants";
import { PageTitle } from "../page-title";

interface ErrorBoundaryProps {
  error: unknown;
}

export const ErrorBoundary = ({ error }: ErrorBoundaryProps) => {
  if (error === NOT_LOGGED_IN_ERROR) {
    return <RequireAuth />;
  }
  if (isRouteErrorResponse(error) || error instanceof Response) {
    return <PageTitle>{error.statusText}</PageTitle>;
  }
  console.error(error);
  return (
    <div className="container">
      <PageTitle>Something went wrong</PageTitle>
      <div className="d-flex justify-content-center mt-4 mb-5">
        <FontAwesomeIcon icon={faBugs} size="5x" className="text-primary" />
      </div>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Details</Accordion.Header>
          <Accordion.Body className="text-white bg-dark">
            <pre>
              {_.toString(error)}
              <hr />
              {JSON.stringify(error, null, 2)}
            </pre>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
