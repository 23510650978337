import _ from "lodash";
import { useEffect, useState } from "react";

import { Doc, Docs } from "../../../api";
import { isAuthenticatedValue, useAuth } from "../../../auth";
import { Nav } from "./nav.component";

interface ConnectedNavProps {
  onSelect?: () => void;
}

export const ConnectedNav = ({ onSelect }: ConnectedNavProps) => {
  const [docs, setDocs] = useState<Doc[]>([]);
  const auth = useAuth();
  useEffect(() => {
    const controller = new AbortController();
    Docs.fetch({ signal: controller.signal }).then(setDocs).catch(_.noop);
    return () => controller.abort();
  }, []);
  const isAuthed = isAuthenticatedValue(auth);
  const userId = isAuthed ? auth.userId : undefined;
  const signOut = isAuthed ? auth.signOut : undefined;
  return (
    <Nav
      isAuthed={isAuthed}
      userId={userId}
      signOut={signOut}
      onSelect={onSelect}
      resources={docs}
    />
  );
};
