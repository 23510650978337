import _ from "lodash";
import { Button, ButtonGroup } from "react-bootstrap";

interface ButtonSelectProps<T extends string>
  extends React.ComponentPropsWithoutRef<typeof ButtonGroup> {
  /**
   * The currently selected option.
   */
  value: T;
  /**
   * A list of options to show.
   */
  options: { value: T; label: string; disabled?: boolean }[];
  /**
   * Change handler for when option is selected.
   */
  onChange: (option: T) => void;
  /**
   * Flag to disable the input.
   */
  disabled?: boolean;
  /**
   * Optional label for button select.
   */
  label?: string;
}

/**
 * Component for selecting between options using buttons.
 */
export const ButtonSelect = <T extends string>({
  value,
  options,
  onChange,
  disabled = false,
  label,
  ...props
}: ButtonSelectProps<T>) => {
  return (
    <div>
      {_.isString(label) && (
        <label className="d-block form-label">{label}</label>
      )}
      <ButtonGroup aria-label="Button select" {...props}>
        {options.map((option) => (
          <Button
            className="px-3"
            disabled={disabled || option.disabled}
            onClick={() => onChange(option.value)}
            key={option.value}
            variant={option.value === value ? "secondary" : "outline-secondary"}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};
