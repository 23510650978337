import _ from "lodash";

import { AuthContextValue } from "./context";

type Listener = (value: AuthContextValue) => void;

export const listeners: Listener[] = [];

export const listen = (cb: Listener) => {
  listeners.push(cb);
  return () => _.pull(listeners, cb);
};
