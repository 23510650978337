import _ from "lodash";
import { DateTime } from "luxon";

import attributes from "../../attributes";
import { AttributeId, ResourceId, TIMEZONE_API } from "../../constants";
import { ActivityData } from "../../types";
import { Tooltip } from "../tooltip";

export const formatResourceValue = (value: number, precision: number = 2) =>
  _.toString(_.round(value, precision));

export const getFormattedResourceValue = (
  data: ActivityData[],
  resourceId: ResourceId,
  precision: number,
  scale: number = 1
) =>
  formatResourceValue(
    _.get(
      _.find(data, (item) => item[AttributeId.Resource].id === resourceId),
      "hours",
      0
    ) * scale,
    precision
  );

export const getFormattedResourceValuesSum = (
  data: ActivityData[],
  resourceId: ResourceId,
  scale: number = 1
) =>
  formatResourceValue(
    _.sum(
      _.map(
        _.filter(data, (item) => item[AttributeId.Resource].id === resourceId),
        "hours"
      )
    ) * scale
  );

interface UsageBreakdownTableData {
  asPlainText: boolean;
}
export const generateUsageBreakdownTableData = (
  data: ActivityData[],
  { asPlainText }: UsageBreakdownTableData = { asPlainText: false }
) => {
  const grouped = _.groupBy(data, "datetime");
  const values = _.map(_.toPairs(grouped), ([datetime, items]) => [
    DateTime.fromISO(datetime).setZone(TIMEZONE_API).toFormat("dd MMM yyyy"),
    getFormattedResourceValue(items, ResourceId.Cpu, 2),
    getFormattedResourceValue(items, ResourceId.Ram, 3, 1 / 10 ** 9),
    getFormattedResourceValue(items, ResourceId.Gpu, 2),
  ]);
  const rows = [
    ...values,
    [
      "Total",
      getFormattedResourceValuesSum(data, ResourceId.Cpu),
      getFormattedResourceValuesSum(data, ResourceId.Ram, 1 / 10 ** 9),
      getFormattedResourceValuesSum(data, ResourceId.Gpu),
    ],
  ];
  const headings = [
    "Resource",
    asPlainText ? (
      "CPU (Core hours)"
    ) : (
      <>
        CPU
        <Tooltip>
          {attributes[AttributeId.Resource].getTooltip(ResourceId.Cpu)}
        </Tooltip>
        <br />
        <small>Core hours</small>
      </>
    ),
    asPlainText ? (
      "RAM (GB hours)"
    ) : (
      <>
        RAM
        <Tooltip>
          {attributes[AttributeId.Resource].getTooltip(ResourceId.Ram)}
        </Tooltip>
        <br />
        <small>GB hours</small>
      </>
    ),
    asPlainText ? (
      "GPU (hours)"
    ) : (
      <>
        GPU
        <Tooltip>
          {attributes[AttributeId.Resource].getTooltip(ResourceId.Gpu)}
        </Tooltip>
        <br />
        <small>hours</small>
      </>
    ),
  ];
  return {
    headings,
    rows,
  };
};
