import _ from "lodash";

import { CONFIG } from "../config";

export const apiUrl = (path: string) =>
  `${_.trimEnd(CONFIG.API_URL, "/")}/${_.trim(path, "/")}/`;

export const transformKeysDeep = (
  transformer: (key: string) => string,
  input: unknown
): unknown => {
  if (_.isArray(input)) {
    return _.map(input, _.partial(transformKeysDeep, transformer));
  } else if (_.isObject(input)) {
    return _.transform(
      input,
      (result, value, key) => {
        result[transformer(key)] = transformKeysDeep(transformer, value);
      },
      {} as { [key: string]: unknown }
    );
  }
  return input;
};
