import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { DateTime, Interval } from "luxon";
import { ReactNode, useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface TimeAgoProps {
  datetime: string;
}

const TimeAgo = ({ datetime }: TimeAgoProps) => {
  const getRoundedDuration = (dt: string) =>
    Interval.fromDateTimes(
      DateTime.fromISO(dt).startOf("minute"),
      DateTime.now().startOf("minute")
    ).toDuration();
  const [duration, setDuration] = useState(getRoundedDuration(datetime));
  useEffect(() => {
    const timeout = setInterval(() => {
      setDuration(getRoundedDuration(datetime));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [datetime]);
  return `${duration.shiftTo("minutes").toHuman({})} ago`;
};

export interface ToastArgs {
  header: ReactNode;
  body: ReactNode;
  type?: "success" | "info" | "warning" | "danger";
}

export interface ToastType extends ToastArgs {
  datetime: string;
  show: boolean;
}

interface ToastsProps {
  toasts: ToastType[];
  onClose: (toast: ToastType) => void;
  onExited: (toast: ToastType) => void;
}

export const Toasts = ({ toasts, onClose, onExited }: ToastsProps) => {
  return (
    <ToastContainer
      className="p-3"
      containerPosition="fixed"
      position="bottom-end"
    >
      {_.map(toasts, (item) => {
        const type = _.get(item, "type", "success");
        const isAuthHide = _.includes(["success", "info"], type);
        return (
          <Toast
            key={item.datetime}
            onClose={() => onClose(item)}
            onExited={() => onExited(item)}
            autohide={isAuthHide}
            show={item.show}
          >
            <Toast.Header>
              <FontAwesomeIcon
                icon={faCircle}
                className={`text-${type} me-2`}
              />
              <strong className="me-auto">{item.header}</strong>
              {!isAuthHide && (
                <small>
                  <TimeAgo datetime={item.datetime} />
                </small>
              )}
            </Toast.Header>
            <Toast.Body>{item.body}</Toast.Body>
          </Toast>
        );
      })}
    </ToastContainer>
  );
};
