import { IconProp, RotateProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Button, Form, Overlay, Popover } from "react-bootstrap";

type RenderPropsFunctionArgs = {
  close: () => void;
};

type RenderPropsFunction = (args: RenderPropsFunctionArgs) => ReactNode;

interface DropdownProps {
  icon: IconProp;
  text: string;
  label: string;
  children: ReactNode | RenderPropsFunction;
  disabled?: boolean;
  id?: string;
  onExited?: () => void;
}

export const Dropdown = ({
  icon,
  text,
  label,
  children,
  disabled,
  id,
  onExited,
}: DropdownProps) => {
  const [show, setShow] = useState(false);
  const popover = useRef<HTMLDivElement>(null);
  const trigger = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        !popover.current?.contains(event.target as Node) &&
        !trigger.current?.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);
  return (
    <Form.Group controlId={id} className="mb-3">
      <Form.Label className="d-block">{label}</Form.Label>
      <Overlay
        show={show}
        target={trigger}
        placement="bottom"
        onExited={onExited}
      >
        <Popover>
          <div ref={popover}>
            <Popover.Body className="p-0">
              {_.isFunction(children)
                ? children({ close: () => setShow(false) })
                : children}
            </Popover.Body>
          </div>
        </Popover>
      </Overlay>
      <Button
        variant="outline-dark"
        className="fs-4 py-3 px-4 text-nowrap w-100 d-inline-flex justify-content-between align-items-center"
        size="lg"
        active={show}
        onClick={() => setShow(!show)}
        ref={trigger}
        disabled={disabled}
        id={id}
        title={text}
      >
        <span className="text-ellipsis overflow-hidden">
          <FontAwesomeIcon className="me-3" icon={icon} />
          <span>{text}</span>
        </span>
        <FontAwesomeIcon
          className="ms-3"
          icon={faChevronDown}
          rotation={(show ? 180 : 0) as RotateProp}
        />
      </Button>
    </Form.Group>
  );
};
