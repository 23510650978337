import _ from "lodash";
import { SimpleLinearRegression } from "ml-regression-simple-linear";

import { calculatePercentChange } from "../../utils/calculate-percent-change";

export const calculateTrendPercent = (data: (number | undefined)[]) => {
  const x = _.reject(_.map(_.keys(data), _.toInteger), (index) =>
    _.isUndefined(data[index])
  );
  const y = _.at(data, x) as number[];
  const regression = new SimpleLinearRegression(x, y);
  const newValue = regression.predict(data.length - 1);
  const oldValue = regression.predict(0);
  const percent = _.round(calculatePercentChange(oldValue, newValue), 2);
  return percent;
};
