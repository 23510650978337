import { Duration } from "luxon";

import { IntervalId } from "./constants";

interface Interval {
  id: IntervalId;
  diurnal: string;
  plural: string;
  format: string;
}

export interface UiInterval extends Interval {
  isDisabled: (duration: Duration) => boolean;
  isAppropriate: (duration: Duration) => boolean;
}

type UiIntervalsType = {
  [key in IntervalId.Hour | IntervalId.Day | IntervalId.Month]: UiInterval;
};

export const uiIntervals: UiIntervalsType = {
  [IntervalId.Hour]: {
    id: IntervalId.Hour,
    diurnal: "hourly",
    plural: "hours",
    format: "T dd LLL",
    isDisabled: (duration) => duration.as("hours") > 400,
    isAppropriate: (duration) =>
      duration < Duration.fromDurationLike({ hours: 72 }),
  },
  [IntervalId.Day]: {
    id: IntervalId.Day,
    diurnal: "daily",
    plural: "days",
    format: "dd LLL yyyy",
    isDisabled: (duration) =>
      duration.as("days") < 2 || duration.as("days") > 400,
    isAppropriate: (duration) =>
      duration >= Duration.fromDurationLike({ hours: 72 }) &&
      duration < Duration.fromDurationLike({ days: 63 }),
  },
  [IntervalId.Month]: {
    id: IntervalId.Month,
    diurnal: "monthly",
    plural: "months",
    format: "LLL yyyy",
    isDisabled: (duration) => duration.as("months") < 2,
    isAppropriate: (duration) =>
      duration >= Duration.fromDurationLike({ days: 63 }),
  },
};

type IntervalsType = {
  [key in IntervalId]: Interval | UiInterval;
};

const intervals: IntervalsType = {
  ...uiIntervals,
  [IntervalId.Quarter]: {
    id: IntervalId.Quarter,
    diurnal: "quarterly",
    plural: "quarters",
    format: "LLL y",
  },
  [IntervalId.Year]: {
    id: IntervalId.Year,
    diurnal: "annually",
    plural: "years",
    format: "y",
  },
};

export default intervals;
