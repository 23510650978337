import clsx, { ClassValue } from "clsx";
import _ from "lodash";
import { ReactNode } from "react";
import { Table as RbTable } from "react-bootstrap";

interface TableProps extends React.ComponentPropsWithoutRef<typeof RbTable> {
  /**
   * A list of table headings.
   */
  headings: ReactNode[];
  /**
   * A list of rows which contains lists of values.
   */
  rows: ReactNode[][];
  /**
   * Optionally provide callback to set TD classes.
   */
  setTdClassName?: ({
    isFirstRow,
    isLastRow,
    isFirstColumn,
    isLastColumn,
    columnIndex,
  }: {
    isFirstRow: boolean;
    isLastRow: boolean;
    isFirstColumn: boolean;
    isLastColumn: boolean;
    columnIndex: number;
  }) => ClassValue[];
  /**
   * Optionally set first column to be sticky when scrolling.
   */
  isSticky?: boolean;
  /**
   * Optionally assign class to table headers.
   */
  thClassName?: string;
  /**
   * Optionally add borders to the table.
   */
  bordered?: boolean;
}

export const Table = ({
  headings,
  rows,
  thClassName,
  className,
  isSticky = false,
  setTdClassName = () => [],
  bordered = false,
  ...props
}: TableProps) => {
  return (
    <RbTable
      responsive
      tabIndex={0}
      className={clsx("h5", className)}
      style={{ borderCollapse: "separate", borderSpacing: 0 }}
      {...props}
    >
      <thead>
        <tr className="border-top border-bottom border-dark">
          {_.map(headings, (heading, index) => (
            <th
              key={index}
              scope="col"
              className={clsx("py-3 text-nowrap border-dark", thClassName, {
                "border-end border-top": bordered,
                "border-start": bordered && index === 0,
                "text-center": index > 0,
                "position-sticky start-0": isSticky && index === 0,
              })}
            >
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {_.map(rows, (row, rowIndex) => (
          <tr key={rowIndex} className="position-relative">
            {_.map(row, (value, columnIndex) => (
              <td
                key={columnIndex}
                className={clsx(
                  "py-3",
                  {
                    "border-end": bordered,
                    "border-start": bordered && columnIndex === 0,
                    "text-center": columnIndex > 0,
                    "position-sticky start-0": isSticky && columnIndex === 0,
                  },
                  ...setTdClassName({
                    isFirstRow: rowIndex === 0,
                    isLastRow: rowIndex === rows.length - 1,
                    isFirstColumn: columnIndex === 0,
                    isLastColumn: columnIndex === row.length - 1,
                    columnIndex,
                  })
                )}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </RbTable>
  );
};
