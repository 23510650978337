import _ from "lodash";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import { Users, WorkspaceUsers } from "../../../api";
import { WorkspaceWithUsersWithFields } from "../../../api";
import { ApiMode } from "../../types";

export interface WorkspaceLoaderData {
  workspace: WorkspaceWithUsersWithFields;
  userIds: string[];
}

interface FetchWorkspaceLoaderDataArgs {
  workspaceId: string;
  signal: AbortSignal;
  mode: ApiMode;
}

const fetchWorkspaceLoaderData = async ({
  workspaceId,
  signal,
  mode,
}: FetchWorkspaceLoaderDataArgs): Promise<WorkspaceLoaderData> => {
  const workspace = await WorkspaceUsers.fetchAndParse({
    mode,
    signal,
    workspaceId,
  });
  let userIds: string[] = [];
  if (mode === ApiMode.Admin) {
    // @TODO: Only the userId of all the fetched data is used. Could optimise endpoints.
    userIds = _.map(await Users.fetchAllAndParse({ signal }), "userId");
  }
  return {
    workspace,
    userIds,
  };
};

export const workspaceLoader =
  (mode: ApiMode) =>
  async ({ request: { signal }, params }: LoaderFunctionArgs) => {
    if (_.isUndefined(params.workspaceId)) {
      throw new Error("Workspace ID is required parameter");
    }
    return defer({
      workspace: fetchWorkspaceLoaderData({
        mode,
        workspaceId: params.workspaceId,
        signal,
      }),
    });
  };
