import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { SubmitFunction } from "react-router-dom";

import { DateRangeInput } from "../../../activity/components/date-range-input";
import { generateCsvUrl } from "../../../activity/utils/generate-csv-url";
import { Spinner } from "../../../common/components/spinner";
import { ReportsActionData, WorkspaceCost } from "./reports-action";

const structure: { key: keyof WorkspaceCost; label: string }[] = [
  { label: "Workspace ID", key: "workspaceId" },
  { label: "Label", key: "label" },
  { label: "WBS", key: "wbs" },
  { label: "Status", key: "status" },
  { label: "Member count", key: "memberCount" },
  { label: "Total cost", key: "totalCost" },
];

interface ReportsProps {
  actionData: ReportsActionData;
  onSubmit: SubmitFunction;
  isSubmitting: boolean;
}

export const Reports = ({
  isSubmitting,
  actionData,
  onSubmit,
}: ReportsProps) => {
  const now = DateTime.now();
  const [dateRange, setDateRange] = useState<[string, string]>([
    now.startOf("year").toISODate(),
    now.toISODate(),
  ]);
  const workspacesCost = _.get(actionData, "workspacesCost");
  const submit = () => {
    onSubmit(JSON.stringify({ dateRange }), {
      method: "POST",
      encType: "application/json",
    });
  };
  return (
    <div className="border p-4 my-5">
      <h3>Workspaces cost</h3>
      <hr />
      <div className="d-flex">
        <DateRangeInput
          value={dateRange}
          onChange={(dateRange) => {
            setDateRange(dateRange);
            if (!_.isUndefined(workspacesCost)) {
              submit();
            }
          }}
          disabled={isSubmitting}
        />
      </div>
      {_.isUndefined(workspacesCost) && (
        <Button
          variant="primary"
          onClick={submit}
          disabled={isSubmitting}
          className="mb-5"
        >
          Generate report
        </Button>
      )}
      {isSubmitting && <Spinner />}
      {!_.isUndefined(workspacesCost) && !isSubmitting && (
        <>
          <Table>
            <thead>
              <tr>
                {_.map(structure, (item) => (
                  <th key={item.key}>{item.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.map(workspacesCost, (row) => (
                <tr key={row.wbs}>
                  {_.map(structure, (item) => (
                    <td key={item.key}>{row[item.key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            as="a"
            href={generateCsvUrl({
              headings: _.map(structure, "label"),
              rows: _.map(workspacesCost, (row) =>
                _.map(structure, (item) => _.toString(row[item.key]))
              ),
            })}
            download="costs-report.csv"
            className="btn btn-primary mt-3"
            disabled={isSubmitting}
          >
            Download CSV
            <FontAwesomeIcon icon={faDownload} className="ms-3" />
          </Button>
        </>
      )}
    </div>
  );
};
