import { ReactNode } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { Footer } from "../footer";
import { Header, NavComponentType } from "../header";

interface PageProps {
  /**
   * Optional children can be passed which will be rendered instead of the router output.
   */
  children?: ReactNode;
  navComponent?: NavComponentType;
}

/**
 * Page component that provides a header and footer which wraps the router output.
 */
export const Page = ({ children, navComponent }: PageProps) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header navComponent={navComponent} />
      <main className="flex-grow-1">{children ? children : <Outlet />}</main>
      <Footer />
      <ScrollRestoration />
    </div>
  );
};
