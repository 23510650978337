import clsx from "clsx";
import _ from "lodash";
import React from "react";

export const CustomDropdownToggleButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, onClick, ...props }, ref) => (
  <button
    ref={ref}
    {...props}
    onClick={(e) => {
      e.preventDefault();
      if (_.isFunction(onClick)) {
        onClick(e);
      }
    }}
    className={clsx(_.without(_.split(className, " "), "dropdown-toggle"))}
  />
));
