import _ from "lodash";

import { ChartData } from "../../types";
import {
  ActivityData,
  AggregatedActivityDataByInterval,
  AttributeValue,
  Grouping,
} from "../../types";

interface GenerateChartDataArgs {
  data: (ActivityData | AggregatedActivityDataByInterval)[];
  groups: AttributeValue[];
  grouping: Grouping;
  aggregator: (
    data: (ActivityData | AggregatedActivityDataByInterval)[]
  ) => number;
}

export const generateChartData = ({
  data,
  groups,
  grouping,
  aggregator,
}: GenerateChartDataArgs): ChartData => {
  const dataByDate = _.groupBy(data, "datetime");
  const datetimeAggregates = _.map(
    _.toPairs(dataByDate),
    ([datetime, items]) => ({
      datetime,
      total: aggregator(items),
      breakdown: _.zipObject(
        _.map(groups, "id"),
        _.map(groups, (group) =>
          aggregator(
            _.filter(
              items,
              (item) =>
                _.isUndefined(grouping) ||
                _.isEqual(_.get(item, grouping), group)
            )
          )
        )
      ),
    })
  );
  const totals = _.map(datetimeAggregates, "total");
  const breakdowns = _.map(datetimeAggregates, "breakdown");
  const datetimes = _.map(datetimeAggregates, "datetime");
  let datasets;
  if (_.isEmpty(groups)) {
    datasets = [
      {
        data: totals,
      },
    ];
  } else {
    datasets = _.map(groups, (group) => ({
      label: group.label,
      id: group.id,
      data: _.map(breakdowns, group.id),
    }));
  }
  return {
    labels: datetimes,
    datasets,
  };
};
