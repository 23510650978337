import _ from "lodash";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import { User, Users, Workspaces } from "../../../api";
import { ApiMode } from "../../types";
import { WorkspaceOption } from "../user-workspaces-field";

export interface UsersLoaderData {
  users: User[];
  workspaceOptions: WorkspaceOption[];
}

export const usersLoader = async ({
  request: { signal },
}: LoaderFunctionArgs) => {
  const fetch = async (): Promise<UsersLoaderData> => {
    const users = await Users.fetchAllAndParse({ signal });
    const workspaces = await Workspaces.fetchAndParse({
      signal,
      mode: ApiMode.Admin,
    });
    const workspaceOptions = _.map(workspaces, (item) => ({
      workspaceId: item.workspaceId,
      label: item.label,
    }));
    return { users, workspaceOptions };
  };
  return defer({ users: fetch() });
};
