import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { getSortedRowModel } from "@tanstack/react-table";
import { getFilteredRowModel } from "@tanstack/react-table";
import { useReactTable } from "@tanstack/react-table";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import { UsersWorkspace } from "../../../api";
import paths from "../../../paths";
import { Table } from "../table";

const columnHelper = createColumnHelper<UsersWorkspace>();

interface UserWorkspacesListProps {
  userWorkspaces: UsersWorkspace[];
  onEdit: (userWorkspace: UsersWorkspace) => void;
  onDelete: (workspaceId: string) => void;
  isSubmitting?: boolean;
}

export const UserWorkspacesList = ({
  userWorkspaces,
  onEdit,
  onDelete,
  isSubmitting = false,
}: UserWorkspacesListProps) => {
  const [toDelete, setToDelete] = useState("");
  const isModalShowing = !_.isEmpty(toDelete);
  useEffect(() => {
    if (!isSubmitting) {
      setToDelete("");
    }
  }, [isSubmitting]);
  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColumnDef<UsersWorkspace, any>[] = [
      columnHelper.accessor("workspace.label", {
        id: "label",
        header: () => "Label",
        cell: (info) => (
          <Link
            to={_.replace(
              paths.DASHBOARD_ADMIN_WORKSPACE,
              ":workspaceId",
              info.row.original.workspace.workspaceId
            )}
          >
            {info.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor("workspace.tenancyName", {
        header: () => "Tenancy name",
      }),
      columnHelper.accessor("role", {
        header: () => "Role",
        meta: { filterVariant: "select" },
      }),
      columnHelper.display({
        id: "actions",
        header: () => <div className="text-end w-100">Actions</div>,
        cell: ({ row: { original: userWorkspace } }) => (
          <Stack gap={2} direction="horizontal" className="justify-content-end">
            <Button
              disabled={isSubmitting}
              variant="outline-primary"
              title="Edit"
              size="sm"
              onClick={() => onEdit(userWorkspace)}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
            <Button
              disabled={isSubmitting}
              variant="outline-danger"
              title="Delete"
              size="sm"
              onClick={() => setToDelete(userWorkspace.workspace.workspaceId)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Stack>
        ),
      }),
    ];
    return columns;
  }, [isSubmitting, onEdit]);
  const table = useReactTable({
    initialState: { sorting: [{ id: "label", desc: false }] },
    data: userWorkspaces,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <>
      <Table table={table} />
      <Modal show={isModalShowing} onHide={() => setToDelete("")}>
        <Modal.Header closeButton={!isSubmitting}>
          <Modal.Title>Remove user from workspace</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove user from workspace?
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isSubmitting || !isModalShowing}
            variant="primary"
            onClick={() => onDelete(toDelete)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
