import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import { calculatePercentChange } from "../../utils/calculate-percent-change";

interface TrendInput {
  name: string;
  period: string;
  oldValue: number;
  newValue: number;
}

export const generateTrendsData = (trendInputs: TrendInput[]) =>
  _.map(trendInputs, (trendInput) => generateTrendData(trendInput));

export const generateTrendData = ({
  name,
  period,
  oldValue,
  newValue,
}: {
  name: string;
  period: string;
  oldValue: number;
  newValue: number;
}) => {
  let percent: number | undefined = _.round(
    calculatePercentChange(oldValue, newValue)
  );
  const { text, icon } = getIconAndTextForPercent(name, period, percent);
  if (percent > 0 && oldValue === 0) {
    percent = undefined;
  }
  if (percent === 0 || (oldValue === 0 && newValue === 0)) {
    percent = undefined;
  }
  return {
    percent,
    text,
    icon,
  };
};

export const getIconAndTextForPercent = (
  name: string,
  period: string,
  percent: number
) => {
  let text = name;
  let icon;
  if (percent < 0) {
    text += " decrease";
    icon = faArrowTrendDown.iconName;
  } else if (percent > 0) {
    text += " increase";
    icon = faArrowTrendUp.iconName;
  } else {
    text += " no change";
  }
  text += ` from previous ${period}`;
  return { text, icon };
};
