import _ from "lodash";
import { useEffect } from "react";
import {
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { useToasts } from "../../../common/components/toasts";
import { WorkspacesActionData } from "./workspaces-action";

export const useWorkspaces = () => {
  const auth = useAuth();
  if (!isAuthenticatedValue(auth)) {
    throw new Error("Not authed");
  }
  const { addToast } = useToasts();
  const actionData = useActionData() as WorkspacesActionData | undefined;
  const toasts = _.get(actionData, "toasts", []);
  useEffect(() => {
    _.map(toasts, addToast);
  }, [toasts, addToast]);
  const onSubmit = useSubmit();
  const { state } = useNavigation();
  const navigate = useNavigate();
  const redirectUrl = _.get(actionData, "redirectUrl");
  if (_.isString(redirectUrl)) {
    navigate(redirectUrl);
  }
  const isSubmitting = state === "submitting";
  return {
    isSubmitting,
    onSubmit,
  };
};
