import React from "react";
import { Await, useLoaderData, useSearchParams } from "react-router-dom";

import { useAuth } from "../../../auth";
import { generateReportProps } from "./generate-report-props";
import { Report, ReportContext } from "./report.component";
import { ReportLoaderData } from "./report-loader";

const ConnectedReportInner = (loaderData: ReportLoaderData) => {
  const [searchParams] = useSearchParams();
  const preview = searchParams.has("preview");
  const auth = useAuth();
  const props = generateReportProps({ loaderData, auth });
  return (
    <ReportContext.Provider value={{ preview }}>
      <Report {...props} />
    </ReportContext.Provider>
  );
};

export const ConnectedReport = () => {
  const data = useLoaderData() as { report: Promise<ReportLoaderData> };
  return (
    <React.Suspense fallback={null}>
      <Await resolve={data.report}>
        {(props: ReportLoaderData) => <ConnectedReportInner {...props} />}
      </Await>
    </React.Suspense>
  );
};
