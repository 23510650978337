import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { Spinner } from "../../../common/components/spinner";
import { SpawnerGroups } from "./spawner-groups.component";
import { SpawnerGroupsLoaderData } from "./spawner-groups-loader";
import { useSpawnerGroups } from "./use-spawner-groups";

export interface SpawnerGroupsLoader {
  spawnerGroups: Promise<SpawnerGroupsLoaderData>;
}

export const ConnectedSpawnerGroups = () => {
  const data = useLoaderData() as SpawnerGroupsLoader;
  const { isSubmitting, onSubmit } = useSpawnerGroups();
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.spawnerGroups}>
        {(data: SpawnerGroupsLoaderData) => (
          <SpawnerGroups
            resourceOptionIds={data.resourceOptionIds}
            spawnerGroups={data.spawnerGroups}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Await>
    </React.Suspense>
  );
};
