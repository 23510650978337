import "./table.scss";

import {
  faChevronLeft,
  faChevronRight,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flexRender, RowData, Table as TableType } from "@tanstack/react-table";
import clsx from "clsx";
import _ from "lodash";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Table as BsTable,
} from "react-bootstrap";

declare module "@tanstack/react-table" {
  //allows us to define custom properties for our columns
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "select";
  }
}

const sortIcons = {
  asc: faSortDown,
  desc: faSortUp,
  default: faSort,
};

interface TableProps<T> {
  table: TableType<T>;
}

export const Table = <T,>({ table }: TableProps<T>) => {
  const { pageSize, pageIndex } = table.getState().pagination;
  const total = table.getPrePaginationRowModel().rows.length;
  const first = pageIndex * pageSize + 1;
  const last = _.min([(pageIndex + 1) * pageSize, total]);
  return (
    <>
      <div className="table-responsive overflow-y-visible">
        <BsTable hover>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={clsx("align-middle easi-table__heading", {
                      "easi-table__heading--min-width":
                        header.column.getCanFilter(),
                    })}
                  >
                    {header.isPlaceholder ? null : (
                      <InputGroup size="sm" className="flex-nowrap">
                        {header.column.getCanFilter() ? (
                          <FloatingLabel
                            controlId={header.column.id}
                            label={flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          >
                            {_.get(
                              header.column.columnDef,
                              "meta.filterVariant",
                              "text"
                            ) === "text" && (
                              <Form.Control
                                value={
                                  (header.column.getFilterValue() as string) ||
                                  ""
                                }
                                onChange={(e) =>
                                  header.column.setFilterValue(e.target.value)
                                }
                                className="border-medium"
                                placeholder=""
                              />
                            )}
                            {_.get(
                              header.column.columnDef,
                              "meta.filterVariant",
                              "text"
                            ) === "select" && (
                              <Form.Select
                                aria-label={`Select ${header.column.id}`}
                                value={
                                  (header.column.getFilterValue() as string) ||
                                  ""
                                }
                                onChange={(e) =>
                                  header.column.setFilterValue(e.target.value)
                                }
                                className="border-medium"
                              >
                                <option value="">All</option>
                                {_.map(
                                  _.uniq(
                                    table
                                      .getCoreRowModel()
                                      .flatRows.map((row) =>
                                        _.toString(
                                          row.getValue(header.column.id)
                                        )
                                      )
                                  ),
                                  (value) => (
                                    <option key={value} value={value}>
                                      {value}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            )}
                          </FloatingLabel>
                        ) : (
                          <InputGroup.Text
                            className={clsx(
                              "bg-white fw-bold py-3 fs-6 w-100",
                              {
                                "border-medium": header.column.getCanSort(),
                                "border-0": !header.column.getCanSort(),
                              }
                            )}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </InputGroup.Text>
                        )}
                        {header.column.getCanSort() && (
                          <Button
                            variant="light"
                            onClick={header.column.getToggleSortingHandler()}
                            className="border-medium"
                          >
                            <FontAwesomeIcon
                              size="sm"
                              icon={_.get(
                                sortIcons,
                                _.toString(header.column.getIsSorted()),
                                sortIcons.default
                              )}
                              title="Set sorting order"
                              className={clsx({
                                "text-primary": header.column.getIsSorted(),
                                "text-muted": !header.column.getIsSorted(),
                              })}
                            />
                          </Button>
                        )}
                      </InputGroup>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-group-divider">
            {table.getRowModel().rows.map((row) => (
              <tr className="position-relative" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </BsTable>
      </div>
      {(table.getCanPreviousPage() || table.getCanNextPage()) && (
        <>
          <div className="d-flex justify-content-center">
            <Button
              aria-label="Previous"
              variant="link"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Button
              aria-label="Next"
              variant="link"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
          <div className="text-center">{`${first} - ${last} of ${total}`}</div>
        </>
      )}
    </>
  );
};
