import {
  fetchUserAttributes,
  FetchUserAttributesOutput,
  updateUserAttribute,
  UpdateUserAttributeInput,
} from "@aws-amplify/auth";
import _ from "lodash";
import { useEffect, useState } from "react";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { createToast, useToasts } from "../../../common/components/toasts";
import { Settings } from "./settings.component";

export const ConnectedSettings = () => {
  const auth = useAuth();
  const isAuthed = isAuthenticatedValue(auth);
  const isCognitoUser = isAuthed && !auth.isFederated;
  const [attributes, setAttributes] = useState<FetchUserAttributesOutput>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { addToast } = useToasts();
  useEffect(() => {
    (async () => setAttributes(await fetchUserAttributes()))();
  }, []);
  const onAttributeChange = async (attribute: { [key: string]: string }) => {
    setIsSubmitting(true);
    const key = _.first(_.keys(attribute)) as string;
    const value = _.first(_.values(attribute)) as string;
    try {
      const input: UpdateUserAttributeInput = {
        userAttribute: {
          attributeKey: key,
          value: _.first(_.values(attribute)) as string,
        },
      };
      await updateUserAttribute(input);
      addToast(
        createToast({
          header: "Success",
          body: `Updated ${key} to ${value}`,
        })
      );
    } catch {
      addToast(
        createToast({
          header: "Error",
          body: `Failed to updated ${key} to ${value}`,
          type: "danger",
        })
      );
    }
    setIsSubmitting(false);
  };
  if (!attributes) {
    return null;
  }
  return (
    <Settings
      addToast={addToast}
      name={attributes.name as string}
      isSubmitting={isSubmitting}
      onAttributeChange={onAttributeChange}
      isCognitoUser={isCognitoUser}
    />
  );
};
