import _ from "lodash";

import { Table } from "../../../common/components/table";
import intervals from "../../intervals";
import { AttributeValue, GroupedCostData } from "../../types";
import { DataControlsValue } from "../../types";
import { formatCurrency } from "../../utils/format-currency";
import { ActivityExports } from "../activity-exports";
import { ActivitySection } from "../activity-section";
import { Chart } from "../chart";
import { Stats } from "../stats";
import { generateStats } from "../stats/generate-stats";
import { generateCostsChartData } from "./generate-costs-chart-data";
import { generateCostsTableData } from "./generate-costs-table-data";

interface CostsProps {
  groups: AttributeValue[];
  isLoading?: boolean;
  dataControlsValue: DataControlsValue;
  data: GroupedCostData[];
}

export const Costs = ({
  groups,
  isLoading = false,
  dataControlsValue,
  data,
}: CostsProps) => {
  const { datetimeRange, intervalId, grouping } = dataControlsValue;
  const isStickyTable = !_.isEmpty(grouping) || !_.isEmpty([1]);
  return (
    <>
      <ActivitySection title="Cost Overview">
        <div className="my-3 mx-n3">
          <Stats
            data={generateStats({
              data,
              datetimeRange,
              intervalId,
              groups,
              grouping,
            })}
          />
        </div>
        <Chart
          isLoading={isLoading}
          data={generateCostsChartData({ data })}
          datetimeFormat={intervals[intervalId].format}
          grouping={grouping}
          yFormatter={formatCurrency}
        />
      </ActivitySection>
      <ActivitySection title="Breakdown" shadow={false}>
        <Table
          bordered
          isSticky={isStickyTable}
          setTdClassName={({ isFirstColumn, columnIndex, isLastRow }) => [
            {
              "fw-bold":
                ((isFirstColumn || isLastRow || columnIndex < 3) &&
                  isStickyTable) ||
                columnIndex < 2,
            },
          ]}
          {...generateCostsTableData({
            data,
            datetimeRange,
            intervalId,
            grouping,
          })}
        />
      </ActivitySection>
      <ActivityExports
        csv={{
          name: "costs",
          contents: generateCostsTableData({
            data,
            datetimeRange,
            intervalId,
            grouping,
            asPlainText: true,
          }),
        }}
      />
    </>
  );
};
