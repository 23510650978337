import { useNavigation } from "react-router-dom";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { Page } from "./page.component";

export const ConnectedPage = () => {
  const auth = useAuth();
  const { state } = useNavigation();
  if (!isAuthenticatedValue(auth)) {
    throw Error(
      "Should not be rendering dashboard page if user is not authenticated."
    );
  }
  return <Page hasRole={auth.hasRole} isLoading={state === "loading"} />;
};
