import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { Spinner } from "../../../common/components/spinner";
import { TabComponentMode } from "../../types";
import { useWorkspace } from "./use-workspace";
import { Workspace } from "./workspace.component";
import { WorkspaceLoaderData } from "./workspace-loader";

export interface WorkspaceLoader {
  workspace: Promise<WorkspaceLoaderData>;
}

interface ConnectedWorkspaceProps {
  mode: TabComponentMode;
}

export const ConnectedWorkspace = ({ mode }: ConnectedWorkspaceProps) => {
  const data = useLoaderData() as WorkspaceLoader;
  const { isSubmitting, onSubmit, authedUserId } = useWorkspace();
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.workspace}>
        {({ workspace, userIds }: WorkspaceLoaderData) => (
          <Workspace
            authedUserId={authedUserId}
            workspace={workspace}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            mode={mode}
            userIds={userIds}
          />
        )}
      </Await>
    </React.Suspense>
  );
};
