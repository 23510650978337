import { fetcher } from "../auth";
import { apiUrl } from "./utils";

export interface Doc {
  label: string;
  href: string;
}

interface RequestArguments {
  signal: AbortSignal;
}

export const Docs = {
  async fetch({ signal }: RequestArguments): Promise<Doc[]> {
    const { data } = await fetcher({ url: apiUrl("docs"), signal });
    return data;
  },
};
