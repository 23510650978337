import _ from "lodash";
import { DateTime, Interval } from "luxon";

import attributes from "../../attributes";
import {
  AttributeId,
  IntervalId,
  TIMEZONE_API,
  TIMEZONE_DISPLAY,
} from "../../constants";
import intervals from "../../intervals";
import { AttributeValue, GroupedCostData, ReactTableData } from "../../types";
import { PlainTextTableData } from "../../types";
import { DatetimeRange } from "../../types";
import { formatCurrency } from "../../utils/format-currency";
import { Tooltip } from "../tooltip";

type RawCells = number[][];

interface RawTableData {
  headings: PlainTextTableData["headings"];
  rows: RawCells;
}

interface GenerateCostsTableDataArgs {
  data: GroupedCostData[];
  datetimeRange: DatetimeRange;
  intervalId: IntervalId;
  grouping?: AttributeId;
}

interface GeneratePlainTextCostsTableDataArgs
  extends GenerateCostsTableDataArgs {
  asPlainText?: true;
}

interface GenerateReactCostsTableDataArgs extends GenerateCostsTableDataArgs {
  asPlainText?: false;
}

export const generateCostsTableData: {
  (args: GeneratePlainTextCostsTableDataArgs): PlainTextTableData;
  (args: GenerateReactCostsTableDataArgs): ReactTableData;
} = ({ data, datetimeRange, intervalId, grouping, asPlainText = false }) => {
  const generator = _.flow([
    generateOriginalRawCostsTableData,
    _.partial(extendWithAverageAndTotal, _, { datetimeRange, intervalId }),
    (tableData: RawTableData) => {
      const hasMultipleRows = tableData.rows.length > 1;
      if (hasMultipleRows) {
        return extendWithTotalRow(tableData);
      }
      return tableData;
    },
    (tableData: RawTableData) => ({
      headings: tableData.headings,
      rows: formatCells(tableData.rows),
    }),
    (formattedTableData: PlainTextTableData) => {
      const hasMultipleRows = formattedTableData.rows.length > 1;
      if (hasMultipleRows) {
        const groups = extractGroups(data);
        return {
          rows: extendWithGroupLabels(
            formattedTableData.rows,
            groups,
            grouping,
            asPlainText
          ),
          headings: formattedTableData.headings,
        };
      }
      return formattedTableData;
    },
  ]);
  return generator({ data, intervalId });
};

interface GenerateOriginalRawCostsTableDataArgs {
  data: GroupedCostData[];
  intervalId: IntervalId;
}

export const generateOriginalRawCostsTableData = ({
  data,
  intervalId,
}: GenerateOriginalRawCostsTableDataArgs): RawTableData => {
  const datetimes = _.map(data, "datetime");
  return {
    headings: _.map(datetimes, (datetime) =>
      DateTime.fromISO(datetime, { zone: TIMEZONE_DISPLAY }).toFormat(
        intervals[intervalId].format
      )
    ),
    rows: _.zip(
      ..._.map(data, ({ items }) => _.map(items, "cost"))
    ) as RawCells,
  };
};

export const extendWithAverageAndTotal = (
  { rows, headings }: RawTableData,
  {
    datetimeRange,
    intervalId,
  }: {
    datetimeRange: DatetimeRange;
    intervalId: IntervalId;
  }
): RawTableData => {
  const extenedCells = _.map(rows, (dimension) => {
    const duration = Interval.fromDateTimes(
      DateTime.fromISO(datetimeRange[0], { zone: TIMEZONE_API }),
      DateTime.fromISO(datetimeRange[1], { zone: TIMEZONE_API })
    ).toDuration(intervalId);
    const intervalCount = duration.as(intervalId);
    const total = _.sum(dimension);
    const average = total / intervalCount;
    return _.concat(total, average, dimension);
  });
  return {
    rows: extenedCells,
    headings: _.concat(
      "Total",
      `${_.startCase(intervals[intervalId].diurnal)} average`,
      headings
    ),
  };
};

export const extendWithTotalRow = ({ rows, headings }: RawTableData) => ({
  rows: _.zip(
    ..._.map(_.zip(...rows), (values) => _.concat(values, _.sum(values)))
  ) as RawCells,
  headings: _.concat("Item", headings),
});

export const formatCells = (rows: RawCells) =>
  _.map(rows, (row) => _.map(row, formatCurrency));

export const extractGroups = (data: GroupedCostData[]) =>
  _.map(
    _.get(data, "[0].items", []) as GroupedCostData["items"],
    (item) => item.group
  );

export const extendWithGroupLabels = (
  formattedCells: PlainTextTableData["rows"],
  groups: AttributeValue[],
  grouping: AttributeId | undefined,
  asPlainText: boolean
) => {
  const groupingLabels = _.concat(
    _.map(groups, (item) => {
      return asPlainText ? (
        item.label
      ) : (
        <>
          {item.label}
          {_.isString(grouping) && (
            <Tooltip>{attributes[grouping].getTooltip(item.id)}</Tooltip>
          )}
        </>
      );
    }),
    "Total"
  );
  return _.map(formattedCells, (row, index) =>
    _.concat(groupingLabels[index], row)
  );
};
