import "lodash.product";

import _ from "lodash";
import { DateTime, Interval } from "luxon";

import { AttributeId, IntervalId, TIMEZONE_API } from "../constants";
import { DataControlsValue, GroupedCostData, Workspace } from "../types";
import {
  ActivityData,
  AggregatedActivityDataByInterval,
  AttributeOptions,
  Attributes,
  DatetimeRange,
  Grouping,
} from "../types";
import {
  extractAttributeOptions,
  filterAttributeOptions,
} from "./attribute-options";

export const filterActivityDataBySelectedAttributes = (
  data: ActivityData[],
  selectedAttributes: Partial<Attributes>
): ActivityData[] => _.filter(data, _.matches(selectedAttributes));

export const filterActivityDataByDatetimeRange = (
  data: ActivityData[],
  [start, end]: DatetimeRange
): ActivityData[] =>
  _.filter(data, ({ datetime }) => datetime >= start && datetime < end);

export const generateDatetimesAtIntervalOverRange = ({
  datetimeRange: [start, end],
  intervalId,
}: {
  datetimeRange: DatetimeRange;
  intervalId: IntervalId;
}) => {
  const datetimeInterval = Interval.fromDateTimes(
    DateTime.fromISO(start, {
      zone: TIMEZONE_API,
    }).startOf(intervalId),
    DateTime.fromISO(end, {
      zone: TIMEZONE_API,
    }).endOf(intervalId)
  );
  const splits = datetimeInterval.splitBy({ [intervalId]: 1 });
  const datetimes = _.map(
    splits,
    (item) => (item.start as DateTime).toISO() as string
  );
  return datetimes;
};

export const generateActivityDataDefaults = (
  datetimeRange: DatetimeRange,
  attributeOptions: AttributeOptions,
  intervalId: IntervalId
): ActivityData[] => {
  const datetimes = generateDatetimesAtIntervalOverRange({
    intervalId,
    datetimeRange,
  });
  const product = _.product<unknown>(
    datetimes,
    [0],
    ..._.map(attributeOptions, (options) =>
      _.map(_.reject(options, "disabled"), "value")
    )
  );
  const keys: (keyof ActivityData)[] = [
    "datetime",
    "cost",
    ...(_.keys(attributeOptions) as (keyof AttributeOptions)[]),
  ];
  const resultValues = _.map(
    product,
    (item) => _.zipObject(keys, item) as unknown as ActivityData
  );
  return resultValues;
};

export const aggregateRequestAndUsageAverages = (
  data: Pick<ActivityData, "cost" | "requestAverage" | "usageAverage">[]
): Pick<ActivityData, "requestAverage" | "usageAverage"> => {
  if (_.some(_.map(data, "requestAverage"), _.isUndefined)) {
    return {};
  }
  const totalCost = _.sum(_.map(data, "cost"));
  // Cost-weighted average.
  const requestAverage =
    _.sum(
      _.map(
        data,
        ({ cost, requestAverage }) => cost * (requestAverage as number)
      )
    ) / totalCost;
  const usageAverage =
    _.sum(
      _.map(data, ({ cost, usageAverage }) => cost * (usageAverage as number))
    ) / totalCost;
  return {
    requestAverage,
    usageAverage,
  };
};

export const alignActivityDataToInterval = (
  data: ActivityData[],
  intervalId: IntervalId
) =>
  _.map(data, ({ datetime, ...rest }) => ({
    ...rest,
    datetime: DateTime.fromISO(datetime, { zone: TIMEZONE_API })
      .startOf(intervalId)
      .toISO() as string,
  }));

export const aggregateActivityDataByInterval = (
  data: ActivityData[]
): AggregatedActivityDataByInterval[] => {
  const groups = _.groupBy(data, "datetime");
  const aggregated = _.map(groups, (item) => ({
    datetime: _.get(_.first(item), "datetime") as string,
    cost: _.sumBy(item, "cost"),
    hours: _.sumBy(item, "hours"),
    efficiency: calculateTotalEfficiency(item),
  }));
  return _.sortBy(aggregated, "datetime");
};

export const aggregateActivityDataByIntervalAndAttributes = (
  data: ActivityData[]
): ActivityData[] => {
  const groups = _.groupBy(data, (item) =>
    JSON.stringify(
      _.pick(
        item,
        _.concat(_.values(AttributeId), "datetime") as Array<
          AttributeId | string
        >
      )
    )
  );
  const aggregated: ActivityData[] = _.map(groups, (item) => ({
    ...(_.first(item) as ActivityData),
    cost: _.sumBy(item, "cost"),
    hours: _.sumBy(item, "hours"),
    efficiency: calculateTotalEfficiency(item),
    ...aggregateRequestAndUsageAverages(item),
  }));
  return _.sortBy(aggregated, "datetime");
};

export const calculateTotalEfficiency = (
  data: Pick<ActivityData, "cost" | "requestAverage" | "usageAverage">[]
): number | undefined => {
  const legit = _.filter(
    data,
    (item) => _.isNumber(item.usageAverage) && _.isNumber(item.requestAverage)
  );
  if (_.isEmpty(legit)) {
    return undefined;
  }
  // In equation form: ((cpuEfficiency * cpuCost) + (ramEfficiency * ramCost)) / (cpuCost + ramCost)
  const numerator = _.sum(
    _.map(legit, (item) => {
      if (item.requestAverage === 0) {
        return 0;
      }
      return (
        ((item.usageAverage as number) / (item.requestAverage as number)) *
        item.cost
      );
    })
  );
  const denominator = _.sum(_.map(legit, "cost"));
  if (denominator === 0) {
    return 0;
  }
  return numerator / denominator;
};

export const extractUsageData = (activity: ActivityData[]) => {
  const resourceIds = _.uniq(_.map(activity, "resource.id"));
  const resourceIdsWithUsageData = _.filter(resourceIds, (id) =>
    _.some(
      activity,
      (item) => id === item.resource.id && _.isNumber(item.usageAverage)
    )
  );
  return _.filter(activity, (item) =>
    _.includes(resourceIdsWithUsageData, item.resource.id)
  );
};

export const getGroupsFromData = (grouping: Grouping, data: ActivityData[]) => {
  if (_.isUndefined(grouping)) {
    return [];
  }
  return _.uniqBy(
    _.map(data, (item) => item[grouping]),
    "id"
  );
};

const extendAttributeOptionsWithAllWorkspaces = (
  attributeOptions: AttributeOptions,
  workspaces: Workspace[]
): AttributeOptions => {
  attributeOptions[AttributeId.Workspace] = _.uniqBy(
    _.concat(
      attributeOptions[AttributeId.Workspace],
      _.map(workspaces, (workspace) => ({
        value: {
          id: workspace.workspaceId,
          label: workspace.label,
        },
        disabled: false,
      }))
    ),
    "value.id"
  );
  return attributeOptions;
};

interface PrepareActivityDataArgs {
  data: ActivityData[];
  datetimeRange: DatetimeRange;
  intervalId: IntervalId;
  selectedAttributes: Partial<Attributes>;
  workspaces: Workspace[];
}

export const prepareActivityData = ({
  data,
  intervalId,
  datetimeRange,
  selectedAttributes,
  workspaces,
}: PrepareActivityDataArgs): ActivityData[] => {
  const dataWithoutDefaults = _.flow([
    _.partial(alignActivityDataToInterval, _, intervalId),
    aggregateActivityDataByIntervalAndAttributes,
    _.partial(filterActivityDataBySelectedAttributes, _, selectedAttributes),
  ])(data);
  const dataDefaults = _.flow([
    extractAttributeOptions,
    _.partial(extendAttributeOptionsWithAllWorkspaces, _, workspaces),
    _.partial(filterAttributeOptions, _, selectedAttributes),
    _.partial(generateActivityDataDefaults, datetimeRange, _, intervalId),
  ])(dataWithoutDefaults);
  return aggregateActivityDataByIntervalAndAttributes(
    _.concat(dataWithoutDefaults, dataDefaults)
  );
};

// @TODO: Possibly extend to have more than just cost (eg. efficiency), but that'll require grouping === resource.
export const generateGroupedCostData = (
  data: ActivityData[],
  grouping: Grouping
): GroupedCostData[] => {
  const groups = getGroupsFromData(grouping, data);
  const groupedByDatetime = _.groupBy(data, "datetime");
  if (_.isUndefined(grouping)) {
    return _.map(_.toPairs(groupedByDatetime), ([datetime, items]) => ({
      datetime,
      items: [
        {
          group: { id: "usage", label: "Usage" },
          cost: _.sum(_.map(items, "cost")),
        },
      ],
    }));
  }
  return _.map(_.toPairs(groupedByDatetime), ([datetime, items]) => ({
    datetime,
    items: _.map(groups, (group) => ({
      group,
      cost: _.sum(
        _.map(
          _.filter(items, (item) => _.isEqual(item[grouping], group)),
          "cost"
        )
      ),
    })),
  }));
};

export const extendWithActivityCosts = (
  data: GroupedCostData[],
  activity: ActivityData[],
  grouping: Grouping
) => {
  const groupedCostsByDatetime = _.fromPairs(
    _.map(
      generateGroupedCostData(activity, grouping),
      ({ datetime, items }) => [datetime, items]
    )
  );
  if (!_.isEmpty(groupedCostsByDatetime)) {
    return _.map(data, ({ items, datetime, ...rest }) => ({
      ...rest,
      datetime,
      items: _.concat(groupedCostsByDatetime[datetime], items),
    }));
  }
  return data;
};

export const extendWithSubscriptionBaseCost = (
  data: GroupedCostData[],
  subscriptionBaseCosts: {
    cost: number;
    datetime: string;
  }[]
): GroupedCostData[] => {
  if (_.sum(_.map(subscriptionBaseCosts, "cost")) === 0) {
    return data;
  }
  const costsByDatetime = _.fromPairs(
    _.map(subscriptionBaseCosts, ({ datetime, cost }) => [datetime, cost])
  );
  return _.map(data, (group) => ({
    ...group,
    items: _.concat(
      {
        group: { id: "base", label: "Subscription Base" },
        cost: _.get(costsByDatetime, group.datetime, 0),
      },
      group.items
    ),
  }));
};

interface GenerateCostsDataArgs {
  dataControlsValue: DataControlsValue;
  activity: ActivityData[];
  subscriptionBaseCosts: {
    datetime: string;
    cost: number;
  }[];
}

export const generateCostsData = ({
  dataControlsValue,
  activity,
  subscriptionBaseCosts,
}: GenerateCostsDataArgs): GroupedCostData[] => {
  const { datetimeRange, intervalId, grouping } = dataControlsValue;
  const datetimes = generateDatetimesAtIntervalOverRange({
    datetimeRange,
    intervalId,
  });
  const container: GroupedCostData[] = _.map(datetimes, (datetime) => ({
    datetime,
    items: [],
  }));
  return _.flow([
    _.partial(extendWithActivityCosts, _, activity, grouping),
    _.partial(extendWithSubscriptionBaseCost, _, subscriptionBaseCosts),
  ])(container);
};
