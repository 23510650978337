import _ from "lodash";

import { AttributeId } from "../constants";
import {
  ActivityData,
  AttributeOptions,
  Attributes,
  Workspace,
} from "../types";

export const extractAttributeOptions = (
  data: ActivityData[]
): AttributeOptions => {
  const attributeOptions: AttributeOptions = _.zipObject(
    _.values(AttributeId),
    _.map(_.values(AttributeId), (id) =>
      _.map(_.uniqBy(_.map(_.values(data), id), "id"), (value) => ({
        value,
        disabled: false,
      }))
    )
  ) as unknown as AttributeOptions;
  return attributeOptions;
};

export const filterAttributeOptions = (
  options: AttributeOptions,
  selected: Partial<Attributes>
): AttributeOptions =>
  _.mapValues(options, (value, key) =>
    _.isUndefined(_.get(selected, key))
      ? value
      : _.filter(value, (option) =>
          _.isMatch(option.value, _.get(selected, key))
        )
  );

interface GetAttributeOptionsArgs {
  data: ActivityData[];
  workspaces: Workspace[];
}

export const getAttributeOptions = ({
  data,
  workspaces,
}: GetAttributeOptionsArgs): AttributeOptions => {
  const extractedOptions = extractAttributeOptions(data);
  const workspaceOptions = _.map(workspaces, (workspace) => ({
    value: {
      id: workspace.workspaceId,
      label: workspace.label,
    },
    disabled: false,
  }));
  return {
    ...extractedOptions,
    workspace: _.uniqBy(
      _.concat(extractedOptions.workspace, workspaceOptions),
      "value.id"
    ),
  };
};
