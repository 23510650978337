import _ from "lodash";

import { GroupedCostData } from "../../types";
import { ChartData } from "../../types";

interface GenerateChartDataArgs {
  data: GroupedCostData[];
}

export const generateCostsChartData = ({
  data,
}: GenerateChartDataArgs): ChartData => {
  const datetimes = _.map(data, "datetime");
  const values = _.zip(..._.map(data, ({ items }) => _.map(items, "cost")));
  const groups: {
    id: string;
    label: string;
  }[] = _.map(_.get(data, "[0].items", []), "group");
  const datasets = _.map(groups, ({ label, id }, index) => ({
    label,
    id,
    data: values[index] as number[],
  }));
  return {
    labels: datetimes,
    datasets,
  };
};
