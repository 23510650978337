import { fetchAuthSession, JWT } from "@aws-amplify/auth";
import _ from "lodash";

export const getAuthTokensFromSession = async () => {
  const session = await fetchAuthSession({ forceRefresh: false });
  const accessToken = _.get(session, "tokens.accessToken") as JWT;
  const idToken = _.get(session, "tokens.idToken") as JWT;
  return {
    idToken: idToken.toString(),
    accessToken: accessToken.toString(),
  };
};
