import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faMicrochip,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import { AttributeId, ResourceId } from "./constants";

const icons = {
  [faUser.iconName]: faUser,
  [faMicrochip.iconName]: faMicrochip,
  [faBuilding.iconName]: faBuilding,
};

library.add(..._.values(icons));

export interface Attribute {
  id: AttributeId;
  icon: keyof typeof icons;
  plural: string;
  singular: string;
  getTooltip(id: string): string | undefined;
}

const attributes: {
  [key in AttributeId]: Attribute;
} = {
  [AttributeId.User]: {
    id: AttributeId.User,
    icon: faUser.iconName,
    plural: "users",
    singular: "user",
    getTooltip: () => undefined,
  },
  [AttributeId.Resource]: {
    id: AttributeId.Resource,
    icon: faMicrochip.iconName,
    plural: "resources",
    singular: "resource",
    getTooltip: (id) => {
      const available = {
        [ResourceId.Cpu]:
          "A hardware component that is the core computational unit in a server. It handles all types of computing tasks required for the operating system and applications to run.",
        [ResourceId.Ram]:
          "A way to store and access the results of operations and tasks.",
        [ResourceId.Gpu]:
          "An electronic circuit that can perform mathematical calculations at high speed and in parallel.",
      };
      return _.get(available, id);
    },
  },
  [AttributeId.Workspace]: {
    id: AttributeId.Workspace,
    icon: faBuilding.iconName,
    plural: "workspaces",
    singular: "workspace",
    getTooltip: () => undefined,
  },
};

export default attributes;
