import _ from "lodash";

import { fetcher } from "../auth";
import { apiUrl } from "./utils";

export interface WorkspaceCharge {
  start: string;
  end: string;
  amount: number;
  workspaceId: string;
}

interface ApiWorkspaceCharge {
  start: string;
  end: string;
  workspace_id: string;
  amount: number;
}
interface RequestArguments {
  signal: AbortSignal;
}

export const WorkspaceCharges = {
  async fetch({ signal }: RequestArguments) {
    const { data } = await fetcher({
      url: apiUrl("workspaces/charges"),
      signal,
    });
    return data;
  },
  parseFromApi(data: ApiWorkspaceCharge[]): WorkspaceCharge[] {
    return _.map(data, ({ workspace_id, amount, ...rest }) => ({
      ...rest,
      amount: amount / 100, // Convert cents into dollars.
      workspaceId: workspace_id,
    }));
  },
  async fetchAndParse(args: RequestArguments): Promise<WorkspaceCharge[]> {
    const data = await WorkspaceCharges.fetch(args);
    return WorkspaceCharges.parseFromApi(data);
  },
};
