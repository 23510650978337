import { Amplify } from "aws-amplify";

import { CONFIG } from "../config";
export { listen } from "./listeners";
export { isAuthenticatedValue, useAuth } from "./context";
export { AuthProvider } from "./auth-provider";
export { fetcher } from "./fetcher";
export { RequireAuth } from "./require-auth";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: CONFIG.USER_POOL_CLIENT_ID,
      userPoolId: CONFIG.USER_POOL_ID,
      signUpVerificationMethod: "code",
      loginWith: {
        oauth: {
          domain: `${CONFIG.USER_POOL_DOMAIN}.auth.${CONFIG.REGION}.amazoncognito.com`,
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          // @TODO: Replace with the following callback URL [`${window.location.origin}/sign-in`], for federated IDP redirect?
          redirectSignIn: [window.location.origin],
          redirectSignOut: [],
          responseType: "code",
        },
      },
    },
  },
});

// @TODO: Could look at writing custom storage https://docs.amplify.aws/react/build-a-backend/auth/concepts/tokens-and-credentials/
// cognitoUserPoolsTokenProvider.setKeyValueStorage(...);
// Provided CookieStorage appears to fail because it doesn't update refresh token.
