import _ from "lodash";
import * as Yup from "yup";

export const getAuthTokensFromCookie = (
  key: string,
  cookie: string = document.cookie
) => {
  const tokens = _.attempt(() =>
    JSON.parse(
      _.fromPairs(_.map(_.split(cookie, "; "), (item) => item.split("=")))[key]
    )
  );
  const schema = Yup.object({
    idToken: Yup.string().required(),
    accessToken: Yup.string().required(),
  });
  if (_.isError(tokens) || !schema.isValidSync(tokens)) {
    return false;
  }
  return tokens;
};
