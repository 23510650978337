import { ReactNode } from "react";

interface PageTitleProps {
  children: ReactNode;
}

export const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <div className="text-center container mt-5">
      <h1 className="fw-bold pt-4 mt-3 pb-3">{children}</h1>
    </div>
  );
};
