import { decodeJWT } from "@aws-amplify/auth";
import _ from "lodash";

interface IdTokenPayload {
  sub: string; // JWT subject.
  "cognito:username": string;
  "cognito:groups": string[];
  email: string;
  email_verified: boolean;
  exp: number; // Expires.
  iat: number; // issued at.
  jti: string; // JWT ID.
  origin_jti: string;
  auth_time: number;
  aud: string; // Audience.
  token_use: "id";
  iss: string; // Issuer.
}

interface CognitoIdTokenPayload extends IdTokenPayload {
  event_id: string;
  name: string;
}

interface FederatedIdTokenPayload extends IdTokenPayload {
  at_hash: string;
  nonce: string;
  identities: {
    userId: string;
    providerName: string;
    providerType: string;
    issuer: string;
    primary: string;
    dateCreated: string;
  }[];
}

const isCognitoIdTokenPayload = (
  payload: unknown
): payload is CognitoIdTokenPayload => _.has(payload, "name");

export const isFederatedIdTokenPayload = (
  payload: unknown
): payload is FederatedIdTokenPayload => _.has(payload, "identities");

export const decodeIdToken = (
  token: string
): CognitoIdTokenPayload | FederatedIdTokenPayload => {
  const payload = decodeJWT(token).payload;
  if (isCognitoIdTokenPayload(payload)) {
    return payload;
  }
  if (isFederatedIdTokenPayload(payload)) {
    return payload;
  }
  throw new Error("Failed to decode ID token.");
};
