import _ from "lodash";

import { AttributeId, ResourceId } from "../../constants";
import intervals from "../../intervals";
import { ActivityData } from "../../types";
import { DataControlsValue } from "../../types";
import {
  aggregateActivityDataByInterval,
  aggregateActivityDataByIntervalAndAttributes,
} from "../../utils/data";
import { getGroupsFromData } from "../../utils/data";
import { ActivitySection } from "../activity-section";
import { Chart } from "../chart";
import { generateChartData } from "../chart/generate-chart-data";
import { TrendValue } from "../trend-value";

interface ResourceUsageProps {
  activity: ActivityData[];
  isLoading: boolean;
  title: string;
  description: string;
  dataControlsValue: Pick<DataControlsValue, "intervalId" | "grouping">;
  yFormatter(value: number): string;
  yLabel: string;
  resourceId: ResourceId;
}
export const ResourceUsage = ({
  activity,
  isLoading,
  title,
  description,
  dataControlsValue,
  yFormatter,
  yLabel,
  resourceId,
}: ResourceUsageProps) => {
  const { intervalId, grouping } = dataControlsValue;
  const resourceActivity = aggregateActivityDataByIntervalAndAttributes(
    _.filter(activity, (item) => item.resource.id === resourceId)
  );
  return (
    <ActivitySection title={title}>
      <div className="d-flex justify-content-between align-items-start">
        <p>{description}</p>
        <TrendValue
          data={_.map(
            aggregateActivityDataByInterval(resourceActivity),
            (item) => item.hours
          )}
        />
      </div>
      <Chart
        isLoading={isLoading}
        data={generateChartData({
          data: resourceActivity,
          groups:
            grouping === AttributeId.Resource
              ? []
              : getGroupsFromData(grouping, resourceActivity),
          grouping: grouping === AttributeId.Resource ? undefined : grouping,
          aggregator: (items) => _.sum(_.map(items, "hours")),
        })}
        datetimeFormat={intervals[intervalId].format}
        grouping={grouping}
        yFormatter={(value) => `${yFormatter(value)} ${yLabel}`}
        options={{
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
              ticks: {
                callback: yFormatter as any, // eslint-disable-line
              },
              title: {
                display: true,
                text: yLabel,
              },
            },
          },
        }}
      />
    </ActivitySection>
  );
};
