import _ from "lodash";

import { AuthStatus } from "./constants";
import { AuthenticatedValue } from "./context";
import { decodeIdToken, isFederatedIdTokenPayload } from "./user";

export const generateAuthenticatedValue = (
  signOut: AuthenticatedValue["signOut"],
  tokens: AuthenticatedValue["tokens"],
  toSignUp: AuthenticatedValue["toSignUp"],
  toSignIn: AuthenticatedValue["toSignIn"]
): AuthenticatedValue => {
  const payload = decodeIdToken(tokens.idToken);
  return {
    hasRole: (role: unknown) => {
      const groups = payload["cognito:groups"];
      return !_.isString(role) || _.isEmpty(role) || _.includes(groups, role);
    },
    tokens,
    userId: _.toLower(payload["email"]),
    authStatus: AuthStatus.Authenticated,
    signOut,
    toSignIn,
    toSignUp,
    isFederated: isFederatedIdTokenPayload(payload),
  };
};
