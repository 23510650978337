import { signOut } from "@aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import _ from "lodash";
import { useEffect, useState } from "react";

import { AuthStatus } from "./constants";
import { AuthContextValue } from "./context";
import { generateAuthenticatedValue } from "./generate-authenticated-value";
import { getAuthTokensFromCookie } from "./get-auth-tokens-from-cookie";
import { getAuthTokensFromSession } from "./get-auth-tokens-from-session";
import { listeners } from "./listeners";

export const useAuthProvider = (authTokensCookieKey: string) => {
  const { authStatus, toSignIn, toSignUp } = useAuthenticator((context) => [
    context.authStatus,
  ]);
  const [value, setValue] = useState<AuthContextValue>({
    authStatus,
    toSignUp,
    toSignIn,
  });
  useEffect(() => {
    (async () => {
      const setValueAndNotifyListeners = (newValue: AuthContextValue) => {
        _.over(listeners)(newValue);
        setValue(newValue);
      };
      const authTokensFromCookie = getAuthTokensFromCookie(authTokensCookieKey);
      if (authTokensFromCookie) {
        setValueAndNotifyListeners(
          generateAuthenticatedValue(
            signOut,
            authTokensFromCookie,
            toSignUp,
            toSignIn
          )
        );
      } else if (authStatus === AuthStatus.Authenticated) {
        const tokens = await getAuthTokensFromSession();
        setValueAndNotifyListeners(
          generateAuthenticatedValue(signOut, tokens, toSignUp, toSignIn)
        );
      } else if (value.authStatus !== authStatus) {
        setValueAndNotifyListeners({ authStatus, toSignUp, toSignIn });
      }
    })();
  }, [authStatus, toSignUp, toSignIn, authTokensCookieKey, value.authStatus]);
  return value;
};
