import _ from "lodash";
import { DateTime, Interval } from "luxon";

import { uiIntervals } from "../intervals";
import { DatetimeRange } from "../types";

export const getAppropriateIntervalId = ([start, end]: DatetimeRange) => {
  const duration = Interval.fromDateTimes(
    DateTime.fromISO(start),
    DateTime.fromISO(end)
  ).toDuration();
  const interval = _.find(uiIntervals, ({ isAppropriate }) =>
    isAppropriate(duration)
  );
  if (_.isUndefined(interval)) {
    throw new Error("No appropriate interval found");
  }
  return interval.id;
};
