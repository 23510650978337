import _ from "lodash";
import { useEffect } from "react";
import { useActionData } from "react-router-dom";

import { ToastType, useToasts } from "./components/toasts";

export interface ActionDataWithToasts {
  toasts: ToastType[];
}

export const useActionToasts = () => {
  const { addToast } = useToasts();
  const actionData = useActionData() as ActionDataWithToasts | undefined;
  const toasts = _.get(actionData, "toasts", []);
  useEffect(() => {
    _.map(toasts, addToast);
  }, [toasts, addToast]);
};
