import _ from "lodash";
import { useState } from "react";
import { Alert } from "react-bootstrap";

import { ButtonSelect } from "../../components/button-select";
import { Costs } from "../../components/costs";
import { DataControls } from "../../components/data-controls";
import { Definitions } from "../../components/definitions";
import { Usage } from "../../components/usage";
import {
  ActivityData,
  AttributeOptions,
  AttributeValue,
  DataControlsType,
  GroupedCostData,
} from "../../types";

export interface ActivityProps {
  activityData: ActivityData[];
  attributeOptions: AttributeOptions;
  dataControls: DataControlsType;
  groups: AttributeValue[];
  costsData: GroupedCostData[];
}

interface ContentOption {
  value: string;
  label: string;
}

const contentOptions: ContentOption[] = [
  { value: "costs", label: "Costs" },
  { value: "usage", label: "Usage" },
];

export const Activity = ({
  activityData,
  groups,
  costsData,
  attributeOptions,
  dataControls,
}: ActivityProps) => {
  const [toShow, setToShow] = useState(contentOptions[0].value);
  const getContent = () => {
    const hasData = !_.isEmpty(_.get(costsData, "0.items", []));
    if (hasData) {
      if (toShow === "costs") {
        return (
          <Costs
            groups={groups}
            dataControlsValue={dataControls.value}
            data={costsData}
          />
        );
      }
      if (toShow === "usage") {
        return (
          <Usage
            activity={activityData}
            dataControlsValue={dataControls.value}
          />
        );
      }
    }
    return <Alert variant="info">No data available for selections</Alert>;
  };
  return (
    <>
      <ButtonSelect
        className="mb-3"
        value={toShow}
        options={contentOptions}
        onChange={setToShow}
      />
      <DataControls
        value={dataControls.value}
        onChange={dataControls.onChange}
        attributeOptions={attributeOptions}
      />
      {getContent()}
      <Definitions />
    </>
  );
};
