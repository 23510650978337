import "./chart.scss";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend as LegendPlugin,
  LegendItem,
  LinearScale,
  Scale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import clsx from "clsx";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

import attributes from "../../attributes";
import { TIMEZONE_DISPLAY } from "../../constants";
import { ChartData, Grouping } from "../../types";
import { ChartLegend } from "../chart-legend";
import { generateScaleColors } from "./colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  LegendPlugin
);

interface CostsChartProps extends React.ComponentPropsWithoutRef<typeof Bar> {
  grouping: Grouping;
  datetimeFormat: string;
  yFormatter(value: number): string;
  data: ChartData;
  isLoading?: boolean;
  enableTooltips?: boolean;
}

const notifier = {
  id: "notifier",
  afterDatasetsUpdate: (
    _charts: unknown,
    _args: unknown,
    options: { afterDatasetsUpdate(): void }
  ) => options.afterDatasetsUpdate(),
};

interface CustomLegendItem extends LegendItem {
  tooltipId?: string;
}

/**
 * Responsive stacked bar chart.
 */
export const Chart = ({
  grouping,
  datetimeFormat,
  yFormatter,
  isLoading = false,
  data,
  options = {},
  enableTooltips = true,
  className,
  ...props
}: CostsChartProps) => {
  const chartRef = useRef<ChartJS<"bar">>();
  const [legendItems, setLegendItemsState] = useState<CustomLegendItem[]>([]);
  const colors = generateScaleColors(data.datasets.length);
  const setLegendItems = () => {
    if (chartRef.current) {
      const items = _.map(
        _.get(chartRef, "current.legend.legendItems", []),
        (item) => ({
          ...item,
          tooltipId: _.get(data.datasets, `[${item.datasetIndex}].id`),
        })
      );
      if (!_.isEqual(items, legendItems)) {
        setLegendItemsState(items);
      }
    }
  };
  useEffect(() => {
    setLegendItems();
  });
  return (
    <>
      {legendItems.length > 1 && (
        <ChartLegend
          items={_.map(
            _.reject(legendItems, (item) => _.isEmpty(item.text)),
            (item) => ({
              text: item.text,
              toggle: () => {
                if (_.isUndefined(chartRef.current)) {
                  return;
                }
                chartRef.current.setDatasetVisibility(
                  item.datasetIndex as number,
                  !chartRef.current.isDatasetVisible(
                    item.datasetIndex as number
                  )
                );
                chartRef.current.update();
              },
              color: item.fillStyle as string,
              selected: !item.hidden,
              tooltip:
                enableTooltips &&
                _.isString(grouping) &&
                _.isString(item.tooltipId)
                  ? attributes[grouping].getTooltip(item.tooltipId)
                  : undefined,
            })
          )}
        />
      )}
      <Bar
        {...props}
        className={clsx(
          "easi-activity-chart",
          {
            "easi-activity-chart--loading": isLoading,
          },
          className
        )}
        ref={chartRef}
        role="img"
        options={_.defaultsDeep({}, options, {
          plugins: {
            notifier: {
              afterDatasetsUpdate: setLegendItems,
            },
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: enableTooltips,
              callbacks: {
                label: (context: TooltipItem<"bar">) => {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += yFormatter(context.parsed.y);
                  }
                  return label;
                },
                title: (context: TooltipItem<"bar">[]) =>
                  _.map(context, (item) => {
                    return DateTime.fromISO(item.label, {
                      zone: TIMEZONE_DISPLAY,
                    }).toFormat(datetimeFormat);
                  }),
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                callback: function (value: number) {
                  const self = this as unknown as Scale;
                  return DateTime.fromISO(self.getLabelForValue(value), {
                    zone: TIMEZONE_DISPLAY,
                  }).toFormat(datetimeFormat);
                },
              },
              stacked: true,
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                callback: (value: number) => yFormatter(value),
              },
              stacked: true,
            },
          },
        })}
        data={{
          ...data,
          datasets: _.map(data.datasets, (dataset, index) => ({
            backgroundColor: [colors[index % colors.length]],
            ...dataset,
          })),
        }}
        data-axe-exclude
        plugins={[notifier]}
      />
    </>
  );
};
