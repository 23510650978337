import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { Spinner } from "../../../common/components/spinner";
import { useUser } from "./use-user";
import { User } from "./user.component";
import { UserLoaderData } from "./user-loader";

export interface UserLoader {
  user: Promise<UserLoaderData>;
}

export const ConnectedUser = () => {
  const data = useLoaderData() as UserLoader;
  const { authedUserId, isSubmitting, onSubmit } = useUser();
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.user}>
        {({ user, workspaces }: UserLoaderData) => (
          <User
            user={user}
            workspaces={workspaces}
            authedUserId={authedUserId}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Await>
    </React.Suspense>
  );
};
