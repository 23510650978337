import _ from "lodash";
import { ReactNode, useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import paths from "../paths";
import { AuthStatus } from "./constants";
import { AuthContext, isAuthenticatedValue } from "./context";

interface RequireAuthProps {
  children?: ReactNode;
  role?: string;
}

export const RequireAuth = ({ children, role }: RequireAuthProps) => {
  const context = useContext(AuthContext);
  const location = useLocation();
  if (context.authStatus === AuthStatus.Configuring) {
    return null;
  }
  if (!isAuthenticatedValue(context)) {
    return <Navigate to={paths.SIGN_IN} state={{ from: location }} replace />;
  }
  const isAuthorised = context.hasRole(role);
  if (!isAuthorised) {
    throw new Response("Forbidden", {
      status: 403,
      statusText: "Permission denied",
    });
  }
  if (_.isUndefined(children)) {
    return <Outlet />;
  }
  return children;
};
