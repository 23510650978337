import clsx from "clsx";
import _ from "lodash";
import { ReactNode } from "react";

interface ActivitySectionProps {
  title?: string;
  children: ReactNode;
  shadow?: boolean;
}

export const ActivitySection = ({
  title,
  children,
  shadow = true,
}: ActivitySectionProps) => (
  <section
    className={clsx("mb-5 p-3", {
      "shadow shadow-sm": shadow,
    })}
  >
    {_.isString(title) && <h2 className="fw-bold mb-3">{title}</h2>}
    {children}
  </section>
);
