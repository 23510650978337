import _ from "lodash";
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Toasts, ToastType } from "./toasts.component";

interface ToastsContextValue {
  addToast: (toast: ToastType) => void;
}

// Default values here are used when there is no provider, good for tests as you don't have to wrap with provider.
export const ToastsContext = createContext<ToastsContextValue>({
  addToast: () => {},
});

interface ToastsProviderProps {
  children: ReactNode;
}

export const ToastsProvider = ({ children }: ToastsProviderProps) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);
  const addToast = useCallback((toast: ToastType) => {
    setToasts((toasts) => _.uniqBy([...toasts, toast], "datetime"));
  }, []);
  const value = useMemo(() => ({ toasts, addToast }), [toasts, addToast]);
  return (
    <ToastsContext.Provider value={value}>
      {children}
      <Toasts
        onClose={(toast) =>
          setToasts(
            _.map(toasts, (item) =>
              item === toast ? { ...toast, show: false } : item
            )
          )
        }
        onExited={(toast) => setToasts(_.reject(toasts, toast))}
        toasts={toasts}
      />
    </ToastsContext.Provider>
  );
};
