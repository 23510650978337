import "./report-footer.scss";

import _ from "lodash";
import React from "react";

import { TIMEZONE_API } from "../../constants";

interface ReportFooterProps {
  timestamp: string;
}

export const ReportFooter = ({ timestamp }: ReportFooterProps) => {
  const footerLinks = [
    { href: "https://www.csiro.au", label: "www.csiro.au" },
    { href: "https://easi.csiro.au", label: "easi.csiro.au" },
    { href: "mailto:hello@easi.csiro.au", label: "hello@easi.csiro.au" },
  ];
  return (
    <div>
      <p className="fs-7 px-3">
        <em>
          * Prices shown are in USD
          <br />* Dates and times shown are in billing timezone ({TIMEZONE_API})
          <br />* Efficiency shown is cost-weighted averages of cpu and ram
          efficiency
        </em>
      </p>
      <hr className="mb-0 mx-3 opacity-25" />
      {/* Accessibility testing complains about multiple footers, but since offender is PDF page it's not relevant, so excluding. */}
      <footer
        data-axe-exclude
        className="p-3 small d-flex justify-content-between"
      >
        <div className="me-3">
          {_.map(footerLinks, ({ href, label }, index) => (
            <React.Fragment key={label}>
              <a
                href={href}
                className="text-decoration-none easi-costs-report-footer__link"
              >
                {label}
              </a>
              {index < footerLinks.length - 1 && " | "}
            </React.Fragment>
          ))}
        </div>
        <div>
          <span>report generated</span> {timestamp}
        </div>
      </footer>
    </div>
  );
};
