import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const icons = {
  [faArrowTrendDown.iconName]: faArrowTrendDown,
  [faArrowTrendUp.iconName]: faArrowTrendUp,
};

export interface Trend {
  text: string;
  icon?: keyof typeof icons;
  percent?: number;
}

interface TrendsProps {
  trends: Trend[];
}

export const Trends = ({ trends }: TrendsProps) => {
  return (
    <div>
      {_.map(trends, ({ icon, percent, text }) => (
        <p className="mb-0 small" key={text}>
          {!_.isUndefined(icon) && (
            <FontAwesomeIcon icon={icons[icon]} className="me-2 text-primary" />
          )}
          {_.isNumber(percent) && <strong>{Math.abs(percent)}%</strong>} {text}
        </p>
      ))}
    </div>
  );
};
