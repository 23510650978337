import _ from "lodash";
import { DateTime, Interval } from "luxon";

import { WorkspaceCharge } from "../../api";
import { IntervalId } from "../constants";
import intervals from "../intervals";
import { DatetimeRange } from "../types";
import { generateDatetimesAtIntervalOverRange } from "./data";

interface GenerateSubscriptionBaseCostsArgs {
  workspaceCharges: WorkspaceCharge[];
  datetimeRange: DatetimeRange;
  intervalId: IntervalId;
}

export const generateSubscriptionBaseCosts = ({
  datetimeRange,
  intervalId,
  workspaceCharges,
}: GenerateSubscriptionBaseCostsArgs) => {
  const datetimes = generateDatetimesAtIntervalOverRange({
    datetimeRange,
    intervalId,
  });
  const rangeStart = DateTime.fromISO(datetimeRange[0]);
  const rangeEnd = DateTime.fromISO(datetimeRange[1]);
  const payments = _.map(workspaceCharges, ({ start, end, amount }) => ({
    interval: Interval.fromDateTimes(
      DateTime.fromISO(start),
      DateTime.fromISO(end)
    ),
    amount,
  }));
  return _.map(datetimes, (datetime) => ({
    datetime,
    cost: getSubscriptionBaseCostForDatetime({
      datetime,
      rangeStart,
      rangeEnd,
      payments,
      intervalId,
    }),
  }));
};

interface GetSubscriptionBaseCostForDatetimeArgs {
  datetime: string;
  intervalId: IntervalId;
  rangeStart: DateTime;
  rangeEnd: DateTime;
  payments: {
    amount: number;
    interval: Interval;
  }[];
}

const getSubscriptionBaseCostForDatetime = ({
  datetime,
  rangeStart,
  rangeEnd,
  payments,
  intervalId,
}: GetSubscriptionBaseCostForDatetimeArgs) => {
  const start = DateTime.max(DateTime.fromISO(datetime), rangeStart);
  const end = DateTime.min(
    start.plus({ [intervals[intervalId].id]: 1 }),
    rangeEnd
  );
  const target = Interval.fromDateTimes(start, end);
  return _.sum(
    _.map(payments, (payment) => {
      const intersection = target.intersection(payment.interval);
      if (_.isNull(intersection)) {
        return 0;
      }
      const overlap = intersection.toDuration().milliseconds;
      const total = payment.interval.toDuration().milliseconds;
      return payment.amount * (overlap / total);
    })
  );
};
