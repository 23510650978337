import { AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";
import { ReactNode, useState } from "react";

import { fetcher as defaultFetcher } from "../../../auth/fetcher";

export class StatusError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = "StatusError";
  }
}

interface FileDownloaderProps {
  children: ({
    isLoading,
    onClick,
    error,
  }: {
    isLoading: boolean;
    onClick: () => void;
    error: unknown;
  }) => ReactNode;
  name: string;
  requestConfig: AxiosRequestConfig;
  fetcher?: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
}

export const FileDownloader = ({
  children,
  requestConfig,
  name,
  fetcher = defaultFetcher,
}: FileDownloaderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();
  const onClick = async () => {
    setError(undefined);
    setIsLoading(true);
    try {
      const response = await fetcher(
        _.defaults({ responseType: "blob" }, requestConfig)
      );
      if (response.status !== 200) {
        throw new StatusError("Failed to download file.", response.status);
      }
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      link.download = name;
      link.click();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return children({ isLoading, onClick, error }) as ReactNode;
};
