import { fetcher } from "../auth";
import { apiUrl } from "./utils";

export interface App {
  name: string;
  image: string;
  text: string;
  link: string;
}

interface RequestArguments {
  signal: AbortSignal;
}

export const Apps = {
  async fetch({ signal }: RequestArguments): Promise<App[]> {
    const { data } = await fetcher({ url: apiUrl("apps"), signal });
    return data;
  },
};
