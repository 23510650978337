import "./tokens.scss";

import { faCopy as regularCopy } from "@fortawesome/free-regular-svg-icons";
import { faCopy as solidCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { PageTitle } from "../../../common/components/page-title";

interface TokenProps {
  label: string;
  value: string;
}

enum States {
  Copied,
  RecentlyCopied,
  Default,
}

const Token = ({ label, value }: TokenProps) => {
  const [state, setState] = useState(States.Default);
  useEffect(() => {
    if (state === States.Copied) {
      setState(States.RecentlyCopied);
    } else if (state === States.RecentlyCopied) {
      const timeout = setTimeout(() => setState(States.Default), 2000);
      return () => clearTimeout(timeout);
    }
  }, [state]);
  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center">
        <h2>{label}</h2>
        <div>
          {state === States.RecentlyCopied && "Copied!"}
          <button
            className="btn btn-link px-2"
            onClick={() => {
              navigator.clipboard.writeText(value);
              setState(States.Copied);
            }}
          >
            <FontAwesomeIcon
              className="fs-4"
              icon={state === States.RecentlyCopied ? solidCopy : regularCopy}
            />
          </button>
        </div>
      </div>
      <pre
        className={clsx(
          "text-break text-wrap border border-primary p-3 easi-tokens__value",
          {
            "bg-light": state === States.Default,
            "bg-primary-subtle": state === States.RecentlyCopied,
          }
        )}
        onClick={(e) => {
          const selection = window.getSelection();
          if (selection) {
            const range = document.createRange();
            range.selectNodeContents(e.currentTarget);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }}
        role="button"
      >
        {value}
      </pre>
    </div>
  );
};

export const Tokens = () => {
  const auth = useAuth();
  if (!isAuthenticatedValue(auth)) {
    throw new Error("Not authed");
  }
  return (
    <div className="container">
      <PageTitle>Tokens</PageTitle>
      <Token label="Access" value={auth.tokens.accessToken} />
      <Token label="ID" value={auth.tokens.idToken} />
    </div>
  );
};
