import _ from "lodash";
import React from "react";
import { Accordion } from "react-bootstrap";

import { PageTitle } from "../../../common/components/page-title";

const content = [
  {
    heading: "About EASI",
    items: [
      {
        question: "What does EASI do?",
        answer: (
          <>
            CSIRO's EASI Platform is a powerful data analytics platform that
            processes satellite imagery at scale. Building upon the Open Data
            Cube, EASI unlocks the potential of Earth Observation data to
            revolutionise how we understand and manage our planet, helping you
            to tackle complex global challenges, from climate analysis to
            resource management.
          </>
        ),
      },
      {
        question: "How much data expertise do I need to use the EASI platform?",
        answer: (
          <>
            The main entry point for using EASI is JupyterLab. In JupyterLab we
            develop our science and do our work with Jupyter notebooks in a web
            browser. There are many resources available for Jupyter notebooks.
            Many of these notebooks and code resources are shared in Git
            repositories, such as GitHub, BitBucket or AzureDevOps.
            <br />
            EASI data science predominately uses Python libraries, and nearly
            all examples are Python-based, including the Open Data Cube.
            <br />
            We recommend users familiarise themselves with:
            <ul>
              <li>Python</li>
              <li>JupyterLab</li>
              <li>Git</li>
            </ul>
            EASI can support other data science languages such as R. Please
            contact us if you can help support such features.
          </>
        ),
      },
      {
        question: "What datasets does EASI have access to?",
        answer: (
          <>
            You can see the datasets that are available in the EASI Open Data
            Cube. You can also access any other public datasets online or
            private data sets that you personally have access to.
          </>
        ),
      },
      {
        question: "What other data sets can I access as an EASI user?",
        answer: (
          <>
            An EASI user can access any commonly available dataset they can
            usually access (DEA, university libraries, etc.). It is important to
            consider efficiency, storage and transfer when interacting with a
            remote dataset from EASI.
          </>
        ),
      },
      // {
      //   question: "What subscription options are available?",
      //   answer: (
      //     <>
      //       You can review all available subscription options here (link to
      //       pricing page.
      //     </>
      //   ),
      // },
    ],
  },
  {
    heading: "Technical Help",
    items: [
      {
        question: "What is a notebook?",
        answer: (
          <>
            Jupyter is a web-based integrated development environment (IDE) for
            creating and sharing computational documents, code and data - also
            known as notebooks.
          </>
        ),
      },
      {
        question: "Can I link my own AWS account to the EASI platform?",
        answer: (
          <>
            All work done in EASI runs on the underlying EASI Amazon Web
            Services.
            <br />
            You can link to your own AWS account for access to data (S3
            buckets), APIs, and AWS services hosted externally. If the resources
            are not public, AWS cross-account authorisation can be established
            to grant EASI users access.
          </>
        ),
      },
      {
        question:
          "What hardware specs do I need to run computations effectively?",
        answer: (
          <>
            Virtual hardware requirements for a computation will be similar to
            the requirements when running on local hardware (the computer
            processing device(s) on which EASI is installed).
            <br />
            Our experience has shown that, as well as scaling up virtual
            hardware to speed up workloads, there may be gains in cost
            efficiency by only paying for virtual hardware when it's being used
            and by modifying architectures to take advantage of cloud-native
            techniques and technologies.
          </>
        ),
      },
      {
        question: "Where is my data stored?",
        answer: (
          <>
            EASI provides a persistent home directory for each user that is
            stored on AWS Elastic File System (EFS) and some user scratch space
            on S3. Both are in the region where EASI is deployed and only
            available to that user.
            <br />
            For larger datasets, long-term storage and publishing, we recommend
            moving your data onto S3 buckets in your own AWS account, where you
            can manage its access and lifecycle.
          </>
        ),
      },
      {
        question: "Can I integrate my own datasets into EASI?",
        answer: (
          <>
            EASI allows users to access any data or services on the web. For
            private datasets hosted in another AWS account, we provide
            documentation on how to enable cross-account authorisation for
            secure access within EASI.
          </>
        ),
      },
      {
        question: "What type of datasets can I upload?",
        answer: (
          <>
            Users are free to upload any datasets they have legal access to. For
            larger datasets, we recommend hosting them somewhere outside EASI to
            reduce costs and enable more efficient access when using distributed
            workloads in EASI.
          </>
        ),
      },
      {
        question: "How does the EASI platform integrate with my existing API?",
        answer: (
          <>
            The code you run on EASI can access APIs that are available via the
            internet. You will need to provide any authentication credentials as
            usual. We also provide documentation describing how to set up AWS
            cross-account authorisation to APIs that are hosted in your own AWS
            account.
          </>
        ),
      },
      {
        question: "How do I share my data sets or views publicly?",
        answer: (
          <>
            User storage on EASI cannot be shared directly. You can upload your
            datasets or other artifacts into your own AWS account or publishing
            platform, such as GitHub, from within EASI.
          </>
        ),
      },
      {
        question: "How do I connect to external AWS Buckets?",
        answer: (
          <>
            The AWS command line tools, Boto3 library in Python and standard
            tools for accessing buckets over HTTP are all available in EASI.
          </>
        ),
      },
      {
        question: "Can I install new libraries into my EASI environment?",
        answer: (
          <>
            You can create Python virtual environments or build new libraries in
            your EASI home directory and use them in EASI's JupyterLab.
          </>
        ),
      },
    ],
  },
  {
    heading: "User Administration",
    items: [
      {
        question:
          "Am I entitled to a discount if I am part of an educational institution, research organisation or NFP?",
        answer: (
          <>
            We have a discounted academic license if the platform is used
            exclusively for research.
            <br />
            Please contact our team for more information.
          </>
        ),
      },
    ],
  },
];

export const Faq = () => (
  <>
    <PageTitle>FAQ</PageTitle>
    <div className="container pt-4">
      {_.map(content, ({ heading, items }) => (
        <React.Fragment key="heading">
          <h2 className="h3">{heading}</h2>
          <Accordion className="mb-5" alwaysOpen>
            {_.map(items, ({ question, answer }) => (
              <Accordion.Item eventKey={question} key={question}>
                <Accordion.Header>{question}</Accordion.Header>
                <Accordion.Body>{answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </React.Fragment>
      ))}
    </div>
  </>
);
