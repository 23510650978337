import _ from "lodash";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import { ResourceOptions, SpawnerGroup, SpawnerGroups } from "../../../api";

export interface SpawnerGroupsLoaderData {
  resourceOptionIds: string[];
  spawnerGroups: SpawnerGroup[];
}

export const spawnerGroupsLoader = async ({
  request: { signal },
}: LoaderFunctionArgs) => {
  const loader = async (): Promise<SpawnerGroupsLoaderData> => {
    const spawnerGroups = await SpawnerGroups.fetchAndParse({ signal });
    const resourceOptions = await ResourceOptions.fetchAndParse({ signal });
    return {
      spawnerGroups,
      resourceOptionIds: _.map(resourceOptions, "id"),
    };
  };
  return defer({ spawnerGroups: loader() });
};
