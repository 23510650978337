import _ from "lodash";
import { ReactNode, useRef, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

interface CsvImportProps {
  children: ({ onClick }: { onClick: () => void }) => ReactNode;
  onSubmit: (value: FormData) => void;
  schema: { id: string; description: string; example: string }[];
}

export const CsvImport = ({ children, onSubmit, schema }: CsvImportProps) => {
  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      {show && (
        <label className="visually-hidden">
          <input
            ref={inputRef}
            accept=".csv,text/csv"
            type="file"
            onChange={(e) => {
              if (_.isNull(e.target.files)) {
                return;
              }
              const formData = new FormData();
              formData.append("file", e.target.files[0]);
              onSubmit(formData);
              setShow(false);
            }}
          />
          File upload
        </label>
      )}
      {children({
        onClick: () => setShow(true),
      })}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Import from CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The following lists the columns that the CSV can contain.</p>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              {_.map(schema, (item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.description}</td>
                  <td>
                    <em>{item.example}</em>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              if (!_.isNull(inputRef.current)) {
                inputRef.current.click();
              }
            }}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
