import _ from "lodash";
import { DateTime, Interval } from "luxon";

import attributes from "../../attributes";
import { AttributeId, IntervalId, TIMEZONE_API } from "../../constants";
import intervals from "../../intervals";
import { AttributeValue, DatetimeRange, GroupedCostData } from "../../types";
import { formatCurrency } from "../../utils/format-currency";
import { StatsData } from "./stats.component";

export interface GenerateStatsArgs {
  data: GroupedCostData[];
  datetimeRange: DatetimeRange;
  intervalId: IntervalId;
  grouping?: AttributeId;
  groups: AttributeValue[];
}

export const generateStats = ({
  data,
  groups,
  grouping,
  intervalId,
  datetimeRange,
}: GenerateStatsArgs): StatsData => {
  const total = _.sum(_.map(data, ({ items }) => _.sum(_.map(items, "cost"))));
  const duration = Interval.fromDateTimes(
    DateTime.fromISO(datetimeRange[0], { zone: TIMEZONE_API }),
    DateTime.fromISO(datetimeRange[1], { zone: TIMEZONE_API })
  ).toDuration(intervalId);
  const stats: StatsData = {
    costs: [],
    counts: [],
  };
  const intervalCount = duration.as(intervalId);
  stats.counts.push({
    label: _.startCase(intervals[intervalId].plural),
    value: _.round(intervalCount > 0 ? intervalCount : 0, 2),
  });
  if (_.isString(grouping)) {
    stats.counts.push({
      label: _.startCase(attributes[grouping].plural),
      value: groups.length,
    });
  }
  stats.costs.push({
    label: "Total",
    value: formatCurrency(total),
  });
  stats.costs.push({
    label: `${_.startCase(intervals[intervalId].diurnal)} average`,
    value: formatCurrency(intervalCount > 0 ? total / intervalCount : 0),
  });
  return stats;
};
