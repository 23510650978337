import _ from "lodash";

import { Users } from "../../../api";
import { createToast, ToastType } from "../../../common/components/toasts";
import { ActionDataWithToasts } from "../../../common/hooks";
import paths from "../../../paths";
import { UserFormSubmissionWithWorkspaces } from "../user-form";

export interface UsersActionData {
  toasts: ToastType[];
  redirectUrl?: string;
}

const processUsersAction = async (
  request: Request
): Promise<UsersActionData> => {
  const actionData: UsersActionData = {
    toasts: [],
  };
  const isCsvImportRequest = request.headers
    .get("Content-Type")
    ?.startsWith("multipart/form-data");
  if (isCsvImportRequest) {
    await Users.importCsv(request);
  } else {
    const values = await request.json();
    if (request.method === "POST") {
      await Users.create(values as UserFormSubmissionWithWorkspaces);
      actionData.toasts.push(
        createToast({
          header: "Successfully added user",
          body: `User with ID ${values.userId} has been added.`,
        })
      );
      actionData.redirectUrl = _.replace(
        paths.DASHBOARD_ADMIN_USER,
        ":userId",
        values.userId
      );
    } else if (request.method === "DELETE") {
      await Users.delete(values);
      actionData.toasts.push(
        createToast({
          header: "Successfully removed user",
          body: `User with ID ${values.userId} has been removed.`,
        })
      );
    }
  }
  return actionData;
};

export const usersAction = async ({
  request,
}: {
  request: Request;
}): Promise<ActionDataWithToasts> => {
  const toasts: ToastType[] = [];
  try {
    const actionData = await processUsersAction(request);
    return actionData;
  } catch (error) {
    if (error instanceof Error) {
      toasts.push(
        createToast({
          header: error.name,
          body: error.message,
          type: "danger",
        })
      );
    } else {
      throw error;
    }
  }
  return { toasts };
};
