import _ from "lodash";

import { PlainTextTableData } from "../types";

interface GenerateCsvUrlOptions {
  transpose?: boolean;
}

export const generateCsvUrl = (
  { headings, rows }: PlainTextTableData,
  overrides?: GenerateCsvUrlOptions
) => {
  const options = _.defaults({}, overrides, { transpose: false });
  let data = _.zip(headings, ...rows);
  if (!options.transpose) {
    data = _.zip(...data);
  }
  const csv = _.join(
    _.map(data, (row) =>
      _.join(
        _.map(row, (value) => `"${_.replace(_.toString(value), '"', '""')}"`),
        ","
      )
    ),
    "\n"
  );
  const csvData = new Blob([csv], { type: "text/csv" });
  return URL.createObjectURL(csvData);
};
