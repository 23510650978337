import _ from "lodash";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import {
  User,
  Users,
  UserWithWorkspaces,
  Workspaces,
  WorkspaceWithUsers,
} from "../../../api";
import { ApiMode } from "../../types";

export interface UserLoaderData {
  user: UserWithWorkspaces;
  workspaces: WorkspaceWithUsers[];
}

export const generateUserWithWorkspaces = (
  user: User,
  workspaces: WorkspaceWithUsers[]
): UserWithWorkspaces => {
  const userWithWorkspaces: UserWithWorkspaces = {
    ...user,
    workspaces: [],
  };
  _.each(workspaces, (workspace) => {
    const workspaceUser = _.find(workspace.users, {
      userId: user.userId,
    });
    if (workspaceUser) {
      userWithWorkspaces.workspaces.push({
        workspace,
        role: workspaceUser.role,
      });
    }
  });
  return userWithWorkspaces;
};

const fetchUserLoaderData = async (
  userId: string,
  signal: AbortSignal
): Promise<UserLoaderData> => {
  const user = await Users.fetchAndParse({ signal, userId });
  // @TODO: Could look at reducing the amount of data fetched here. There are all users for workspaces which aren't needed.
  const workspaces = await Workspaces.fetchAndParse({
    signal,
    mode: ApiMode.Admin,
  });
  const userWithWorkspaces = generateUserWithWorkspaces(user, workspaces);
  return { user: userWithWorkspaces, workspaces };
};

export const userLoader = async ({ request, params }: LoaderFunctionArgs) => {
  if (_.isUndefined(params.userId)) {
    throw new Error("User ID is required parameter");
  }
  return defer({ user: fetchUserLoaderData(params.userId, request.signal) });
};
