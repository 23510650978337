import "./page.scss";

import clsx from "clsx";
import _ from "lodash";
import { ReactNode } from "react";
import { Nav as RbNav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

import { PageTitle } from "../../../common/components/page-title";
import { CONFIG } from "../../../config";
import paths from "../../../paths";

const tabs = _.compact([
  {
    to: paths.DASHBOARD,
    name: "Home",
    role: _.get(CONFIG.TAB_ROLES, "Home"),
    end: true,
  },
  {
    to: paths.DASHBOARD_ACTIVITY,
    name: "Activity",
    role: _.get(CONFIG.TAB_ROLES, "Activity"),
    end: false,
  },
  // @TODO: Use this tab to show link to form to apply to get a workspace.
  {
    to: paths.DASHBOARD_WORKSPACES,
    name: "Workspaces",
    role: _.get(CONFIG.TAB_ROLES, "Workspaces"),
    end: false,
  },
  {
    to: paths.DASHBOARD_ADMIN,
    name: "Admin",
    role: _.get(CONFIG.TAB_ROLES, "Admin"),
    end: false,
  },
]);

interface PageProps {
  children?: ReactNode;
  hasRole: (role: string) => boolean;
  isLoading?: boolean;
}

export const Page = ({ children, hasRole, isLoading = false }: PageProps) => {
  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <div className="container pt-4">
        <RbNav variant="pills" className="py-5 small flex-column flex-lg-row">
          {_.map(
            _.filter(tabs, ({ role }) => hasRole(role)),
            ({ name, to, end }) => (
              <RbNav.Item key={name} className="d-flex align-items-center">
                <NavLink
                  to={to}
                  end={end}
                  className="nav-link text-decoration-none rounded-pill px-4 py-2 fw-bold fs-7"
                >
                  {name}
                </NavLink>
              </RbNav.Item>
            )
          )}
        </RbNav>
        <div
          data-axe-exclude={isLoading}
          className={clsx("easi-dashboard-page__content", {
            "opacity-50": isLoading,
          })}
        >
          {children ? children : <Outlet />}
        </div>
      </div>
    </>
  );
};
