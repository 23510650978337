import "./app-list.scss";

import _ from "lodash";
import { Card, Col, Row } from "react-bootstrap";

import { App } from "../../../api";

interface AppListProps {
  apps: App[];
}

export const AppList = ({ apps }: AppListProps) => {
  return (
    <Row>
      {_.map(apps, (app) => (
        <Col sm="6" lg="4" xl="3" key={app.name} className="mb-4">
          <Card
            className="h-100 text-decoration-none easi-app-list__item"
            as="a"
            href={app.link}
            target="_blank"
          >
            <div className="ratio ratio-1x1">
              <Card.Img
                className="p-4"
                variant="top"
                src={`data:image/svg+xml;base64,${btoa(app.image)}`}
                alt={`${app.name} logo`}
              />
            </div>
            <Card.Body className="d-flex flex-column h-100 justify-content-between">
              <div className="mb-3">
                <Card.Title className="text-primary">{app.name}</Card.Title>
                <Card.Text>{app.text}</Card.Text>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
