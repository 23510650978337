import "./Stats.scss";

import _ from "lodash";
import { Stack } from "react-bootstrap";

export interface StatsData {
  costs: { label: string; value: string }[];
  counts: { label: string; value: number }[];
}

interface StatsProps {
  data: StatsData;
}

export const Stats = ({ data }: StatsProps) => (
  <div className="easi-costs-stats">
    <Stack
      direction="horizontal"
      gap={3}
      className="flex-wrap justify-content-between px-3 py-4 text-left"
    >
      {_.map([...data.costs, ...data.counts], ({ label, value }) => (
        <div key={label}>
          <div className="fs-4 lh-1">{label}</div>
          <div className="fw-bold fs-5">{value}</div>
        </div>
      ))}
    </Stack>
  </div>
);
