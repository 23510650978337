import "./index.scss";

import { Authenticator } from "@aws-amplify/ui-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AuthProvider } from "./auth";
import { ToastsProvider } from "./common/components/toasts";
import { routes } from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(routes);
root.render(
  <React.StrictMode>
    <ToastsProvider>
      <Authenticator.Provider>
        <AuthProvider authTokensCookieKey="pdfConfig">
          <RouterProvider router={router} />
        </AuthProvider>
      </Authenticator.Provider>
    </ToastsProvider>
  </React.StrictMode>
);
