import _ from "lodash";
import * as Yup from "yup";

import { UserProjectValue, WorkspaceUserRole } from "../../../api";

export const userFieldsSchema = Yup.object({
  userId: Yup.string()
    .email()
    .lowercase("User ID needs to be lowercase")
    .required("User ID is required")
    .strict(),
  groups: Yup.array(Yup.string().required("Group is required")),
  projects: Yup.array(
    Yup.object().shape({
      key: Yup.string().required("Project key is required"),
      value: Yup.string()
        .oneOf(
          _.values(UserProjectValue),
          "Project value must be one of the following values: rw, ro, true"
        )
        .required("Project value is required"),
    })
  ),
  workspaces: Yup.array(
    Yup.object({
      workspaceId: Yup.string().required("Workspace must be selected"),
      role: Yup.string()
        .oneOf(_.values(WorkspaceUserRole))
        .required("Role must be selected"),
    })
  ),
});
