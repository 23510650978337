import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { OverlayTrigger, Tooltip as BsTooltip } from "react-bootstrap";

interface TooltipProps {
  children: ReactNode;
}
export const Tooltip = ({ children }: TooltipProps) => {
  if (!children) {
    return null;
  }
  return (
    <OverlayTrigger overlay={<BsTooltip>{children}</BsTooltip>} trigger="click">
      <FontAwesomeIcon role="tooltip" icon={faInfoCircle} className="ms-1" />
    </OverlayTrigger>
  );
};
