import _ from "lodash";
import { DateTime, Interval } from "luxon";

import { IntervalId, TIMEZONE_API } from "./constants";
import { DatetimeRange } from "./types";

export enum ReportPeriodId {
  Month = "month",
  Quarter = "quarter",
  Annual = "annual",
}

export type Scopes = {
  [key in "inner" | "outer"]: {
    datetimeRange: DatetimeRange;
    intervalId: IntervalId;
  };
};

export interface ReportPeriod {
  id: ReportPeriodId;
  label: string;
  getScopes(start: string): Scopes;
  startOptions: { id: string; label: string }[];
}

type ReportPeriods = {
  [key in ReportPeriodId]: ReportPeriod;
};

const now = DateTime.now().setZone(TIMEZONE_API);

export const reportPeriods: ReportPeriods = {
  [ReportPeriodId.Month]: {
    id: ReportPeriodId.Month,
    label: "Monthly",
    startOptions: _.reverse(
      _.map(
        Interval.fromDateTimes(
          now.minus({ years: 1 }).startOf("month"),
          now.minus({ months: 1 }).endOf("month")
        ).splitBy({
          months: 1,
        }),
        ({ start }) => ({
          id: start!.toFormat("yyyy-LL"),
          label: start!.toFormat("d LLL y"),
        })
      )
    ),
    getScopes: (start: string) => {
      const datetime = DateTime.fromISO(start, { zone: TIMEZONE_API });
      return {
        outer: {
          datetimeRange: [
            datetime.minus({ months: 3 }).startOf("month").toISO() as string,
            datetime.endOf("month").toISO() as string,
          ] as DatetimeRange,
          intervalId: IntervalId.Month,
        },
        inner: {
          datetimeRange: [
            datetime.startOf("month").toISO() as string,
            datetime.endOf("month").toISO() as string,
          ] as DatetimeRange,
          intervalId: IntervalId.Day,
        },
      };
    },
  },
  [ReportPeriodId.Quarter]: {
    id: ReportPeriodId.Quarter,
    label: "Quaterly",
    startOptions: _.reverse(
      _.map(
        Interval.fromDateTimes(
          now.minus({ years: 2 }).startOf("quarter"),
          now.minus({ quarters: 1 }).endOf("quarter")
        ).splitBy({
          quarters: 1,
        }),
        ({ start }) => ({
          id: start!.toFormat("yyyy-LL"),
          label: start!.toFormat("d LLL y"),
        })
      )
    ),
    getScopes: (start: string) => {
      const datetime = DateTime.fromISO(start, { zone: TIMEZONE_API });
      return {
        outer: {
          datetimeRange: [
            datetime
              .minus({ quarters: 3 })
              .startOf("quarter")
              .toISO() as string,
            datetime.endOf("quarter").toISO() as string,
          ] as DatetimeRange,
          intervalId: IntervalId.Quarter,
        },
        inner: {
          datetimeRange: [
            datetime.startOf("quarter").toISO() as string,
            datetime.endOf("quarter").toISO() as string,
          ] as DatetimeRange,
          intervalId: IntervalId.Month,
        },
      };
    },
  },
  [ReportPeriodId.Annual]: {
    id: ReportPeriodId.Annual,
    label: "Annually",
    startOptions: _.reverse(
      _.map(
        Interval.fromDateTimes(
          now.minus({ years: 5 }).startOf("year"),
          now.minus({ years: 1 }).endOf("year")
        ).splitBy({
          years: 1,
        }),
        ({ start }) => ({
          id: start!.toFormat("yyyy"),
          label: start!.toFormat("d LLL y"),
        })
      )
    ),
    getScopes: (start: string) => {
      const datetime = DateTime.fromISO(start, { zone: TIMEZONE_API });
      return {
        outer: {
          datetimeRange: [
            datetime.minus({ years: 3 }).startOf("year").toISO() as string,
            datetime.endOf("year").toISO() as string,
          ] as DatetimeRange,
          intervalId: IntervalId.Year,
        },
        inner: {
          datetimeRange: [
            datetime.startOf("year").toISO() as string,
            datetime.endOf("year").toISO() as string,
          ] as DatetimeRange,
          intervalId: IntervalId.Month,
        },
      };
    },
  },
};
