import _ from "lodash";

const variables = [
  "API_URL",
  "BASE_DOMAIN",
  "COSTS_API_URL",
  "IS_BILLING_ENABLED",
  "FEDERATED_IDENTITY_PROVIDER",
  "PDF_REPORT_URL",
  "REGION",
  "TAB_ROLES",
  "USER_POOL_CLIENT_ID",
  "USER_POOL_ID",
  "USER_POOL_DOMAIN",
];

// Environment variables enter the app either via process.env when in development
// or by being injected into window.__env__ when deployed.
export const CONFIG = _.zipObject(
  variables,
  _.map(variables, (variable) => {
    const runtimeConfig = _.get(window, `__env__.${variable}`);
    let buildConfig = _.get(import.meta.env, `VITE_${variable}`);
    _.attempt(() => {
      buildConfig = JSON.parse(buildConfig);
    });
    return _.defaultTo(runtimeConfig, buildConfig);
  })
);
