import "./nav.scss";

import _ from "lodash";
import { Nav as RbNav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import paths from "../../../paths";

interface ExternalLink {
  href: string;
  label: string;
}

interface NavProps {
  isAuthed: boolean;
  resources?: ExternalLink[];
  signOut?: () => void;
  userId?: string;
  onSelect?: () => void;
}

/**
 * Primary navigation UI component.
 */
export const Nav = ({
  isAuthed,
  userId,
  resources = [],
  signOut = _.noop,
  onSelect = _.noop,
}: NavProps) => {
  const items: ExternalLink[] = [
    {
      href: "https://research.csiro.au/easi/about/",
      label: "About",
    },
    {
      href: "https://research.csiro.au/easi/features/",
      label: "Features",
    },
    {
      href: "https://research.csiro.au/easi/category/case-studies/",
      label: "Case studies",
    },
    {
      href: "https://research.csiro.au/easi/support/",
      label: "Support",
    },
  ];
  return (
    <RbNav className="small flex-column flex-lg-row">
      {_.map(items, ({ label, href }) => (
        <RbNav.Item key={label} className="d-flex align-items-center">
          <RbNav.Link
            className="easi-nav__link"
            onClick={() => {
              onSelect();
            }}
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {label}
          </RbNav.Link>
        </RbNav.Item>
      ))}
      <NavDropdown title="Resources">
        {_.map(resources, ({ href, label }) => (
          <NavDropdown.Item
            href={href}
            key={href}
            target="_blank"
            rel="noreferrer"
          >
            {label}
          </NavDropdown.Item>
        ))}
        <NavDropdown.Item as={NavLink} to={paths.FAQ}>
          FAQ
        </NavDropdown.Item>
      </NavDropdown>
      {isAuthed ? (
        <NavDropdown title="Account">
          <li>
            <h6 className="dropdown-header">{userId}</h6>
          </li>
          <NavDropdown.Divider />
          <NavDropdown.Item as={NavLink} to={paths.DASHBOARD}>
            Dashboard
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to={paths.SETTINGS}>
            Settings
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={signOut}>Sign out</NavDropdown.Item>
        </NavDropdown>
      ) : (
        <RbNav.Item className="d-flex align-items-center">
          <RbNav.Link
            className="easi-nav__link"
            as={NavLink}
            onClick={() => {
              onSelect();
            }}
            to={paths.SIGN_IN}
          >
            Sign in
          </RbNav.Link>
        </RbNav.Item>
      )}
    </RbNav>
  );
};
