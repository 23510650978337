import _ from "lodash";
import { useEffect, useState } from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { SubmitFunction } from "react-router-dom";

import {
  FileDownloader,
  StatusError,
} from "../../../activity/components/file-downloader";
import { apiUrl, WorkspaceWithUsers } from "../../../api";
import { TabComponentMode } from "../../types";
import { AddWorkspaceModal } from "../add-workspace-modal";
import { CsvImport } from "../csv-import";
import { WorkspacesList } from "../workspaces-list";

interface ModalState {
  show: boolean;
}

interface WorkspacesProps {
  workspaces: WorkspaceWithUsers[];
  isSubmitting?: boolean;
  onSubmit: SubmitFunction;
  userIds: string[];
  mode?: TabComponentMode;
}

const settingsByMode = {
  [TabComponentMode.User]: {
    hasCsvButtons: false,
    canAddWorkspace: false,
  },
  [TabComponentMode.Admin]: {
    hasCsvButtons: true,
    canAddWorkspace: true,
  },
};

export const Workspaces = ({
  onSubmit,
  isSubmitting = false,
  workspaces,
  userIds,
  mode = TabComponentMode.User,
}: WorkspacesProps) => {
  const [modal, setModal] = useState<ModalState>({ show: false });
  useEffect(() => {
    if (!isSubmitting) {
      setModal({ show: false });
    }
  }, [isSubmitting]);
  const settings = settingsByMode[mode];
  return (
    <>
      <div className="d-flex justify-content-start mb-5">
        <Stack direction="horizontal" gap={3}>
          {settings.canAddWorkspace && (
            <Button variant="primary" onClick={() => setModal({ show: true })}>
              Add workspace
            </Button>
          )}
          {settings.hasCsvButtons && (
            <>
              <CsvImport
                schema={[
                  {
                    id: "code",
                    description: "The code assigned to the workspace",
                    example: "worskpace-code",
                  },
                  {
                    id: "label",
                    description: "A label to identify the workspace",
                    example: "Workspace label",
                  },
                  {
                    id: "plan_id",
                    description:
                      "The plan ID associated with the workspace plan",
                    example: "subscription",
                  },
                  {
                    id: "tenancy_name",
                    description: "A unique name for the tenancy within EASI",
                    example: "tenancy-1",
                  },
                ]}
                onSubmit={(formData) =>
                  onSubmit(formData, {
                    method: "post",
                    encType: "multipart/form-data",
                  })
                }
              >
                {({ onClick }) => (
                  <Button variant="primary" onClick={onClick}>
                    Import CSV
                  </Button>
                )}
              </CsvImport>
              <FileDownloader
                requestConfig={{ url: apiUrl("workspaces/csv") }}
                name="workspaces.csv"
              >
                {({ onClick, isLoading, error }) => {
                  if (error instanceof StatusError && error.status === 403) {
                    window.location.reload();
                  } else if (error) {
                    console.error(error);
                  }
                  return (
                    <Button
                      variant={_.isUndefined(error) ? "primary" : "danger"}
                      onClick={onClick}
                      disabled={isLoading}
                    >
                      {_.isUndefined(error)
                        ? "Export CSV"
                        : "Failed. Try again!"}
                    </Button>
                  );
                }}
              </FileDownloader>
            </>
          )}
        </Stack>
      </div>
      {workspaces.length === 0 ? (
        <Alert variant="info">No workspaces</Alert>
      ) : (
        <WorkspacesList workspaces={workspaces} mode={mode} />
      )}
      {settings.canAddWorkspace && (
        <AddWorkspaceModal
          show={modal.show}
          onHide={() => setModal({ show: false })}
          userIds={userIds}
          onSubmit={(values) =>
            onSubmit(JSON.stringify(values), {
              method: "post",
              encType: "application/json",
            })
          }
        />
      )}
    </>
  );
};
