import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { ReactNode, useState } from "react";
import { Form, Stack } from "react-bootstrap";

import { CONFIG } from "../../../config";
import { ReportPeriodId, reportPeriods } from "../../report-periods";
import { PlainTextTableData } from "../../types";
import { generateCsvUrl } from "../../utils/generate-csv-url";
import { ActivitySection } from "../activity-section";
import { ReportDownloader } from "../report-downloader";

interface ActivityExportsProps {
  csv: {
    name: string;
    contents: PlainTextTableData;
  };
}

enum DownloadTypeId {
  Pdf = "pdf",
  Csv = "csv",
}

interface DownloadType {
  id: DownloadTypeId;
  label: string;
  button: ReactNode;
  periods?: { id: string; label: string }[];
  getDates?: (periodId?: ReportPeriodId) => { id: string; label: string }[];
}

type DownloadTypes = {
  [id in DownloadTypeId]?: DownloadType;
};

export const ActivityExports = ({ csv }: ActivityExportsProps) => {
  const [selectedTypeId, setSelectedTypeId] = useState<DownloadTypeId>(
    DownloadTypeId.Csv
  );
  const [periodId, setPeriodId] = useState<ReportPeriodId>(
    ReportPeriodId.Month
  );
  const [date, setDate] = useState<string>(
    reportPeriods[ReportPeriodId.Month].startOptions[0].id
  );
  const types: DownloadTypes = {
    [DownloadTypeId.Csv]: {
      label: "CSV",
      id: DownloadTypeId.Csv,
      button: (
        <a
          href={generateCsvUrl(csv.contents, { transpose: true })}
          download={`${csv.name}.csv`}
          className="btn btn-primary"
        >
          Download
          <FontAwesomeIcon icon={faDownload} className="ms-3" />
        </a>
      ),
    },
  };
  if (_.isString(CONFIG.PDF_REPORT_URL)) {
    types[DownloadTypeId.Pdf] = {
      label: "PDF",
      id: DownloadTypeId.Pdf,
      button: <ReportDownloader periodId={periodId} start={date} />,
      periods: _.values(reportPeriods),
      getDates: (periodId = ReportPeriodId.Month) =>
        reportPeriods[periodId].startOptions,
    };
  }
  const selected = types[selectedTypeId] as DownloadType;
  const getDates = selected.getDates;
  return (
    <ActivitySection title="Export">
      <Stack direction="horizontal" gap={3}>
        <Form.Group controlId="file-type">
          <Form.Label>File type</Form.Label>
          <Form.Select
            value={selectedTypeId}
            onChange={({ target: { value } }) =>
              setSelectedTypeId(value as DownloadTypeId)
            }
          >
            {_.map(types, (type: DownloadType) => (
              <option key={type.id} value={type.id}>
                {type.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {!_.isEmpty(selected.periods) && (
          <Form.Group controlId="usage-period">
            <Form.Label>Usage period</Form.Label>
            <Form.Select
              value={periodId}
              onChange={({ target: { value } }) => {
                setPeriodId(value as ReportPeriodId);
                setDate(
                  reportPeriods[value as ReportPeriodId].startOptions[0].id
                );
              }}
            >
              {_.map(selected.periods, (period) => (
                <option key={period.id} value={period.id}>
                  {period.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        {_.isFunction(getDates) && (
          <Form.Group controlId="start-date">
            <Form.Label>Start date</Form.Label>
            <Form.Select
              value={date}
              onChange={({ target: { value } }) => setDate(value)}
            >
              {_.map(getDates(periodId), (date) => (
                <option key={date.id} value={date.id}>
                  {date.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
      </Stack>
      <div className="pt-3">{selected.button}</div>
    </ActivitySection>
  );
};
