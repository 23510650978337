import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { Spinner } from "../../../common/components/spinner";
import { getAttributeOptions } from "../../utils/attribute-options";
import { Activity } from "./activity.component";
import { ActivityLoaderData } from "./activity-loader";
import { generateActivityProps } from "./generate-activity-props";
import { useDataControls } from "./use-data-controls";

export const ConnectedActivityInner = (loaderData: ActivityLoaderData) => {
  const attributeOptions = getAttributeOptions({
    data: loaderData.activity,
    workspaces: loaderData.workspaces,
  });
  const dataControls = useDataControls({
    datetimeRange: loaderData.datetimeRange,
    attributeOptions,
  });
  const activityProps = generateActivityProps({
    loaderData,
    dataControls,
    attributeOptions,
  });
  return <Activity {...activityProps} />;
};

export const ConnectedActivity = () => {
  const data = useLoaderData() as { activity: Promise<ActivityLoaderData> };
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.activity}>
        {(props: ActivityLoaderData) => <ConnectedActivityInner {...props} />}
      </Await>
    </React.Suspense>
  );
};
