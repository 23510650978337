import _ from "lodash";

const isValueSet = (value: unknown) => !_.isEmpty(value) || _.isNumber(value);

export const generateSubmitValue = <T extends object>(
  before: T | undefined,
  after: T
): T => {
  const picked = _.pickBy(
    after,
    (value, key) => isValueSet(value) || isValueSet(_.get(before, key))
  );
  // Returning null for properties that have been unset.
  const nulled = _.mapValues(picked, (value) =>
    isValueSet(value) ? value : null
  );
  return nulled as T;
};
