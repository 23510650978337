import _ from "lodash";

import { Workspaces } from "../../../api";
import { createToast, ToastType } from "../../../common/components/toasts";
import { ActionDataWithToasts } from "../../../common/hooks";
import paths from "../../../paths";

export interface WorkspacesActionData {
  toasts: ToastType[];
  redirectUrl?: string;
}

const processWorkspacesAction = async ({
  request,
}: {
  request: Request;
}): Promise<WorkspacesActionData> => {
  const actionData: WorkspacesActionData = {
    toasts: [],
  };
  const isCsvImportRequest = request.headers
    .get("Content-Type")
    ?.startsWith("multipart/form-data");
  if (isCsvImportRequest) {
    await Workspaces.importCsv(request);
  } else {
    const values = await request.json();
    if (request.method === "POST") {
      const response = await Workspaces.create(values);
      actionData.toasts.push(
        createToast({
          header: "Successfully added workspace",
          body: `Workspace withith ID ${_.get(response, "data")} has been created.`,
        })
      );
      actionData.redirectUrl = _.replace(
        paths.DASHBOARD_ADMIN_WORKSPACE,
        ":workspaceId",
        response.data
      );
    } else if (request.method === "DELETE") {
      await Workspaces.delete(values);
      actionData.toasts.push(
        createToast({
          header: "Successfully removed workspace",
          body: `Workspace withith ID ${values.workspaceId} has been removed.`,
        })
      );
    }
  }
  return actionData;
};

export const workspacesAction = async ({
  request,
}: {
  request: Request;
}): Promise<ActionDataWithToasts> => {
  try {
    const actionData = await processWorkspacesAction({ request });
    return actionData;
  } catch (error) {
    if (error instanceof Error) {
      return {
        toasts: [
          createToast({
            header: error.name,
            body: error.message,
            type: "danger",
          }),
        ],
      };
    } else {
      throw error;
    }
  }
};
