import { faCircle, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { getSortedRowModel } from "@tanstack/react-table";
import { getFilteredRowModel } from "@tanstack/react-table";
import { useReactTable } from "@tanstack/react-table";
import clsx from "clsx";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import { WorkspaceUserWithUserFields } from "../../../api";
import paths from "../../../paths";
import { TabComponentMode } from "../../types";
import { Table } from "../table";

const columnHelper = createColumnHelper<WorkspaceUserWithUserFields>();

interface WorkspaceUsersListProps {
  workspaceUsers: WorkspaceUserWithUserFields[];
  onEdit: (user: WorkspaceUserWithUserFields) => void;
  onDelete: (userId: string) => void;
  isSubmitting?: boolean;
  authedUserId?: string;
  isAdmin: boolean;
  mode?: TabComponentMode;
}

const settingsByMode = {
  [TabComponentMode.User]: {
    hasActionButtons: false,
    includeLinkToUser: false,
  },
  [TabComponentMode.Admin]: {
    hasActionButtons: true,
    includeLinkToUser: true,
  },
};

export const WorkspaceUsersList = ({
  workspaceUsers,
  onEdit,
  onDelete,
  isSubmitting = false,
  authedUserId,
  isAdmin,
  mode = TabComponentMode.User,
}: WorkspaceUsersListProps) => {
  const settings = settingsByMode[mode];
  const [toDelete, setToDelete] = useState("");
  const isModalShowing = !_.isEmpty(toDelete);
  useEffect(() => {
    if (!isSubmitting) {
      setToDelete("");
    }
  }, [isSubmitting]);
  const hasAction = !_.isEmpty(
    _.reject(workspaceUsers, { userId: authedUserId })
  );
  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColumnDef<WorkspaceUserWithUserFields, any>[] = [
      columnHelper.accessor("userId", {
        header: () => "ID",
        cell: (info) =>
          settings.includeLinkToUser ? (
            <Link
              to={_.replace(
                paths.DASHBOARD_ADMIN_USER,
                ":userId",
                info.row.original.userId
              )}
            >
              {info.getValue()}
            </Link>
          ) : (
            info.getValue()
          ),
      }),
      columnHelper.accessor("name", {
        header: () => "Name",
      }),
      columnHelper.accessor("role", {
        header: () => "Role",
        meta: { filterVariant: "select" },
      }),
      columnHelper.accessor(
        (row) => {
          if (row.enabled === true) {
            return "Enabled";
          }
          if (row.enabled === false) {
            return "Disabled";
          }
          return "Invited";
        },
        {
          id: "enabled",
          header: () => "Status",
          cell: (info) => (
            <>
              <FontAwesomeIcon
                className={clsx("me-2", {
                  "text-danger": info.getValue() === "Disabled",
                  "text-success": info.getValue() === "Enabled",
                  "text-warning": info.getValue() === "Invited",
                })}
                icon={faCircle}
              />
              {info.getValue()}
            </>
          ),
          meta: { filterVariant: "select" },
        }
      ),
    ];
    if (hasAction && (settings.hasActionButtons || isAdmin)) {
      columns.push(
        columnHelper.display({
          id: "actions",
          header: () => <div className="text-end w-100">Actions</div>,
          cell: ({ row: { original: workspaceUser } }) => (
            <div className="text-end">
              {(settings.hasActionButtons ||
                workspaceUser.userId !== authedUserId) && (
                <Stack
                  gap={2}
                  direction="horizontal"
                  className="justify-content-end"
                >
                  <Button
                    disabled={isSubmitting}
                    variant="outline-primary"
                    title="Edit"
                    size="sm"
                    onClick={() => onEdit(workspaceUser)}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                  {(isAdmin || workspaceUser.userId !== authedUserId) && (
                    <Button
                      disabled={isSubmitting}
                      variant="outline-danger"
                      title="Delete"
                      size="sm"
                      onClick={() => setToDelete(workspaceUser.userId)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </Stack>
              )}
            </div>
          ),
        })
      );
    }
    return columns;
  }, [hasAction, authedUserId, isAdmin, isSubmitting, onEdit, settings]);
  const table = useReactTable({
    initialState: { sorting: [{ id: "userId", desc: false }] },
    data: workspaceUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <>
      <Table table={table} />
      <Modal show={isModalShowing} onHide={() => setToDelete("")}>
        <Modal.Header closeButton={!isSubmitting}>
          <Modal.Title>Remove user from workspace</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove user from workspace?
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isSubmitting || !isModalShowing}
            variant="primary"
            onClick={() => onDelete(toDelete)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
