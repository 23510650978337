import { SystemZone } from "luxon";

export enum IntervalId {
  Hour = "hour",
  Day = "day",
  Month = "month",
  Quarter = "quarter",
  Year = "year",
}

export enum AttributeId {
  User = "user",
  Workspace = "workspace",
  Resource = "resource",
}

export enum ResourceId {
  Cpu = "cpu",
  Ram = "ram",
  Gpu = "gpu",
  Network = "network",
  LoadBalancer = "loadBalancer",
  Pv = "pv",
  Shared = "shared",
  External = "external",
}

export const TIMEZONE_LOCAL = new SystemZone().name;
export const TIMEZONE_API = "Australia/Sydney";
export const TIMEZONE_DISPLAY = TIMEZONE_API;
