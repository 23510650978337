import _ from "lodash";

import { fetcher } from "../auth";
import { ApiMode } from "../dashboard/types";
import { plans } from "../plans";
import { AuthProviderUser, WorkspaceUserRole } from "./users";
import { apiUrl } from "./utils";
import { Workspace, WorkspacesResponseItem } from "./workspaces";

export interface WorkspaceUser {
  userId: string;
  role: WorkspaceUserRole;
  workspaceId: string;
}

export interface WorkspaceUserWithUserFields extends WorkspaceUser {
  name: string;
  username: string;
  enabled: boolean | undefined;
}

export interface WorkspaceWithUsersResponseItem extends WorkspacesResponseItem {
  users: {
    user_id: string;
    role: WorkspaceUserRole;
    provided: AuthProviderUser;
  }[];
}

interface RequestArguments {
  signal: AbortSignal;
  mode: ApiMode;
  workspaceId: string;
}

export interface WorkspaceWithUsersWithFields extends Workspace {
  users: WorkspaceUserWithUserFields[];
}

const modes = {
  [ApiMode.User]: {
    url: apiUrl("workspace-users"),
  },
  [ApiMode.Admin]: {
    url: apiUrl("workspaces/all"),
  },
};

// @TODO: DRY this is very similar to Workspace.parseFromApi.
export const WorkspaceUsers = {
  parseFromApi(
    data: WorkspaceWithUsersResponseItem
  ): WorkspaceWithUsersWithFields {
    return {
      workspaceId: data.workspace_id,
      label: data.label,
      numberOfSeatsIncluded: _.get(
        plans[data.plan_id],
        "numberOfSeatsIncluded"
      ),
      status: data.status,
      users: _.map(data.users, (user) => ({
        userId: user.user_id,
        role: user.role,
        workspaceId: data.workspace_id, // @TODO: Don't think this is required.
        name: _.get(user, "provided.name", ""),
        username: _.get(user, "provided.username", ""),
        enabled: _.get(user, "provided.enabled"),
      })),
      planId: data.plan_id,
      code: data.code,
      tenancyName: data.tenancy_name,
      startDate: data.start_date || undefined,
      endDate: data.end_date || undefined,
      costcode: data.costcode || undefined,
      o2d: data.o2d || undefined,
      wbs: data.wbs || undefined,
    };
  },
  async fetch({
    signal,
    mode,
    workspaceId,
  }: RequestArguments): Promise<WorkspaceWithUsersResponseItem> {
    // @TODO: Use workspaceId in request URL instead of filtering out unwanted response items.
    const { data } = await fetcher({ url: modes[mode].url, signal });
    return _.find(data, { workspace_id: workspaceId });
  },
  async fetchAndParse(
    args: RequestArguments
  ): Promise<WorkspaceWithUsersWithFields> {
    const data = await WorkspaceUsers.fetch(args);
    return WorkspaceUsers.parseFromApi(data);
  },
  create(values: WorkspaceUser) {
    return fetcher({
      url: apiUrl(
        `workspace-users/${encodeURIComponent(values.workspaceId)}/${encodeURIComponent(values.userId)}`
      ),
      method: "POST",
      data: {
        role: values.role,
      },
    });
  },
  delete(values: WorkspaceUser) {
    return fetcher({
      url: apiUrl(
        `workspace-users/${encodeURIComponent(values.workspaceId)}/${encodeURIComponent(values.userId)}`
      ),
      method: "DELETE",
    });
  },
  update(values: WorkspaceUser) {
    return fetcher({
      url: apiUrl(
        `workspace-users/${encodeURIComponent(values.workspaceId)}/${encodeURIComponent(values.userId)}`
      ),
      method: "PATCH",
      data: {
        role: values.role,
      },
    });
  },
  async importCsv(request: Request) {
    return fetcher({
      url: apiUrl("workspace-users/csv"),
      method: "POST",
      data: await request.formData(),
      headers: {
        "Content-Type": request.headers.get("Content-Type"),
      },
    });
  },
};
