import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";

import { NOT_LOGGED_IN_ERROR } from "./constants";
import { AuthContextValue, isAuthenticatedValue } from "./context";
import { listen } from "./listeners";

const instance = axios.create();

listen((value: AuthContextValue) => {
  if (isAuthenticatedValue(value)) {
    instance.defaults.headers.common.Authorization = `Bearer ${value.tokens.accessToken}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
});

export const fetcher = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse> => {
  if (!_.has(instance, "defaults.headers.common.Authorization")) {
    await new Promise((resolve, reject) => {
      const signal = config.signal as AbortSignal;
      signal.addEventListener("abort", () => reject(signal.reason));
      listen((value) => {
        if (isAuthenticatedValue(value)) {
          instance.defaults.headers.common.Authorization = `Bearer ${value.tokens.accessToken}`;
          resolve(null);
        } else {
          reject(NOT_LOGGED_IN_ERROR);
        }
      });
    });
  }
  return instance(config);
};
