import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { App } from "../../../api";
import { Spinner } from "../../../common/components/spinner";
import { AppList } from "./app-list.component";

export interface AppListLoader {
  apps: Promise<App[]>;
}

export const ConnectedAppList = () => {
  const data = useLoaderData() as AppListLoader;
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.apps}>
        {(apps: App[]) => <AppList apps={apps} />}
      </Await>
    </React.Suspense>
  );
};
