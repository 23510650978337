import { ReactNode } from "react";

import { AuthContext } from "./context";
import { useAuthProvider } from "./use-auth-provider";

interface AuthProviderProps {
  authTokensCookieKey: string;
  children: ReactNode;
}

export const AuthProvider = ({
  authTokensCookieKey,
  children,
}: AuthProviderProps) => {
  const value = useAuthProvider(authTokensCookieKey);
  if (value.authStatus === "configuring") {
    return null;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
