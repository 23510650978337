import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import _ from "lodash";
import { ReactNode } from "react";
import { Stack } from "react-bootstrap";

import { Tooltip } from "../tooltip";

interface ChartLegendProps {
  items: {
    text: string;
    toggle(): void;
    color: string;
    selected: boolean;
    tooltip?: ReactNode;
  }[];
}
export const ChartLegend = ({ items }: ChartLegendProps) => {
  return (
    <Stack
      as="ul"
      direction="horizontal"
      gap={2}
      className="list-unstyled list-inline flex-wrap"
    >
      {_.map(items, (item) => (
        <li className="list-inline-item" key={item.text}>
          <div className="d-flex align-items-center">
            <div onClick={item.toggle} role="button">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: item.color }}
                className={clsx("me-1", { "opacity-50": !item.selected })}
              />
              <span
                style={{ textDecoration: item.selected ? "" : "line-through" }}
              >
                {item.text}
              </span>
            </div>
            {!!item.tooltip && <Tooltip>{item.tooltip}</Tooltip>}
          </div>
        </li>
      ))}
    </Stack>
  );
};
