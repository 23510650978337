import "./footer.scss";

export const Footer = () => {
  return (
    <footer role="contentinfo" className="easi-footer text-white mt-5">
      <div className="container py-3">
        <p className="small my-3">
          CSIRO acknowledges the Traditional Owners of the land, sea and waters,
          of the area that we live and work on across Australia. We acknowledge
          their continuing connection to their culture and pay our respects to
          their Elders past and present. View our{" "}
          <a
            className="text-white"
            href="https://www.csiro.au/en/about/Indigenous-Science-and-Engagement-Program/Reconciliation-Action-Plan"
          >
            vision towards reconciliation
          </a>
          .
        </p>
      </div>
    </footer>
  );
};
