import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, FieldArray, useField } from "formik";
import { Stack } from "react-bootstrap";

export const UserGroupsField = () => {
  const name = "groups";
  const meta = useField(name)[1];
  return (
    <>
      <label className="form-label">Groups</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((_item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}`}
                      >
                        Group
                      </label>
                      <Field
                        name={`${name}.${index}`}
                        placeholder="Group"
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => remove(index)}
                      aria-label="Remove group"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push("")}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </>
  );
};
