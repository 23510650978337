import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import { calculateTrendPercent } from "./calculate-trend-percent";

export const TrendValue = ({ data }: { data: (number | undefined)[] }) => {
  const percent = calculateTrendPercent(data);
  if (_.isNaN(percent)) {
    return null;
  }
  return (
    <div className="text-nowrap ms-3 p-2 px-3 rounded-pill bg-primary-subtle">
      {percent === 0 ? (
        "No change"
      ) : (
        <>
          <FontAwesomeIcon
            icon={percent > 0 ? faArrowTrendUp : faArrowTrendDown}
            className="me-2"
          />
          {`${Math.abs(percent)}%`}
        </>
      )}
    </div>
  );
};
