import _ from "lodash";

export const generateSeatsText = ({
  numberOfSeatsInUse,
  numberOfSeatsIncluded,
}: {
  numberOfSeatsInUse: number;
  numberOfSeatsIncluded: number | false;
}) => {
  const firstSentence = `${numberOfSeatsInUse} seat${numberOfSeatsInUse === 1 ? "" : "s"} in use.`;
  if (
    _.isNumber(numberOfSeatsIncluded) &&
    numberOfSeatsInUse < numberOfSeatsIncluded
  ) {
    return `${firstSentence} Up to ${numberOfSeatsIncluded} seats can be used without incurring additional costs.`;
  }
  if (
    _.isNumber(numberOfSeatsIncluded) &&
    numberOfSeatsInUse === numberOfSeatsIncluded
  ) {
    return `${firstSentence} Adding more users will result in additional costs.`;
  }
  return firstSentence;
};
