import { TIMEZONE_DISPLAY } from "../../constants";
import { ActivitySection } from "../activity-section";

export const Definitions = () => (
  <ActivitySection title="Definitions">
    <p className="fw-bold">
      <em>* All prices shown are in USD</em>
      <br />
      <em>
        ** Dates and times are shown in billing timezone ({TIMEZONE_DISPLAY})
      </em>
    </p>
  </ActivitySection>
);
