import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, FieldArray, useField } from "formik";
import _ from "lodash";
import { Stack } from "react-bootstrap";

import { WorkspaceUserRole } from "../../../api";

export interface WorkspaceOption {
  workspaceId: string;
  label: string;
}

interface UserWorkspacesFieldProps {
  options: WorkspaceOption[];
}

export const UserWorkspacesField = ({ options }: UserWorkspacesFieldProps) => {
  const name = "workspaces";
  const meta = useField(name)[1];
  return (
    <>
      <label className="form-label">Workspaces</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}.workspaceId`}
                      >
                        Workspace ID
                      </label>
                      <Field
                        name={`${name}.${index}.workspaceId`}
                        as="select"
                        className="form-select form-select-sm"
                        aria-label="Workspace"
                      >
                        {_.isEmpty(item.workspaceId) ? (
                          <option value="">Select</option>
                        ) : (
                          <option value={item.workspaceId}>
                            {_.get(
                              _.find(options, {
                                workspaceId: item.workspaceId,
                              }),
                              "label"
                            )}
                          </option>
                        )}
                        {_.map(
                          _.differenceBy(options, meta.value, "workspaceId"),
                          (option) => (
                            <option
                              key={option.workspaceId}
                              value={option.workspaceId}
                            >
                              {option.label}
                            </option>
                          )
                        )}
                      </Field>
                    </div>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}.role`}
                      >
                        Role
                      </label>
                      <Field
                        name={`${name}.${index}.role`}
                        as="select"
                        className="form-select form-select-sm"
                        aria-label="Workspace role"
                      >
                        {_.isEmpty(item.role) && (
                          <option value="">Select</option>
                        )}
                        <option value={WorkspaceUserRole.User}>User</option>
                        <option value={WorkspaceUserRole.Admin}>Admin</option>
                        <option value={WorkspaceUserRole.Owner}>Owner</option>
                      </Field>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => remove(index)}
                      aria-label="Remove workspace"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}.workspaceId`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                  <ErrorMessage
                    name={`${name}.${index}.role`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push({ role: "", workspaceId: "" })}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </>
  );
};
